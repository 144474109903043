import React from 'react';
import YoungPersonForm from './YoungPersonForm';

const CreateYoungPersonPage = (props) => (<YoungPersonForm
  title="Create a young person's profile"
  path='/cots/invite/young_person'
  { ...props }
/>);

export default CreateYoungPersonPage;
