import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Footer from '../content/Footer';


const sections = [
  {
    title: 'TRAINING',
    faqs: [
      {
        question: 'What if I didn\'t attend face-to-face training?',
        answer: () => (
          <div>
            <span>Training with providers from BfB Labs is a key component of successfully supporting young people and a great opportunity for you to connect with other teachers and clinicians in your area. If you were unable to attend:</span>
            <ul>
              <li>Contact BfB Labs at support@bfb-labs.com about joining another training session</li>
              <li>Review the training materials provided <Link to='/cots/resources'>here</Link></li>
            </ul>
          </div>
        ),
      },
      {
        question: 'Can I review training materials after the session?',
        answer: () => <span>Yes, you can download all training materials and more in the <Link to='/cots/resources'>Resources</Link> section of this site.</span> 
      },
    ],
  },
  {
    title: 'CHOOSING GAME PLAYERS',
    faqs: [
      {
        question: 'How do I choose game players?',
        answer: () => (
          <div>
            <span>Champions of the Shengha Pro is intended for:</span>
            <ul>
              <li>9-18 year olds</li>
              <li>Any gender</li>
              <li>Can benefit from learning emotional self-regulation skills to better deal with stress, anxiety and frustration</li>
              <li>Can be used solely or alongside other treatments</li>
            </ul>
          </div>
        ),
      },      
    ],
  },
  {
    title: 'SUPPORTING GAME PLAYERS',
    faqs: [
      {
        question: 'How do I introduce the game to a new young person?',
        answer: () => (
          <div>
            <span>Make it fun! Tell them they can play a new mobile game to help them master their mind and body and 
              describe how the game works: ”Champions of the Shengha is a fun game with a difference: you control the 
              game with your body and mind. A Magic Transmitter measures your magic power and sends it to the game. 
              To succeed in the Shengha Temple, you need to stay focused and calm under pressure.”</span>
          </div>
        ),
      },
      {
        question: 'How do I send game information to a young person?',
        answer: () => (
          <div>
            <span>Saving a new Young Person profile in the VitaMind Hub will automatically send an SMS to the mobile 
              number provided with the game key and download link to the game.</span>
              <br/>
              <span>You will also need to provide a young person with their own Champions of the Shengha game pack.
              </span>
          </div>
        ),
      },
    ],
  },
  {
    title: 'GAME MAINTENANCE',
    faqs: [
      {
        question: 'What if I run out of licenses?',
        answer: 'When you only have a few licenses remaining, BfB Labs will automatically be notified and will arrange to provide you with more licenses if necessary. If you have any questions, you can contact BfB Labs at support@bfb-labs.com',
      },
      {
        question: 'What if a young person forgets their game keys?',
        answer: 'A young person will need their game key once to unlock Champions of the Shengha Pro after downloading the game to their device. If they forget/lose their game key, they may be prompted to contact you to request it. You can provide the user key to them from their young person’s profile on your Young People list.',
      },
      {
        question: 'When can I expect to see results?',
        answer: 'You can expect to start seeing results once a young person starts playing the game.',
      },
    ],
  },
  {
    title: 'CAN\'T FIND WHAT YOU\'RE LOOKING FOR?',
    faqs: [
      {
        question: 'Contact',
        answer: 'For any other questions please email support@bfb-labs.com',
      },
    ],
  },
];


const useStyles = makeStyles(() => ({
  card: {
    minWidth: 275,
    marginBottom: '16px',
  },
  title: {
    padding: '20px',
    paddingTop: '50px',
  },
  heading: {
    marginBottom: '12px',
  },
  listItem: {
    width: '100%',
  },
}));

function QuestionItem(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Card className={ classes.card }>
      <CardContent>
        <Grid container direction='column' alignItems='flex-start' justify='center'>
          <Grid item xs={ 12 } className={ classes.listItem }>
            <ListItem button onClick={ handleClick } className={ classes.listItem }>
              <ListItemText
                primary={ props.question }
                primaryTypographyProps={ { style: { fontWeight: 800 } } }
              />
              { open ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>
            <Collapse in={ open } timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button>
                  <ListItemText primary={ typeof props.answer === 'function' ? props.answer() : props.answer } />
                </ListItem>

              </List>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}


export default function FAQs() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <Card className={ classes.card }>
        <CardContent>
          <Grid container direction='column' alignItems='flex-start' justify='center'>
            <Grid item xs={ 12 } className={ classes.heading }>
              <Typography variant="h4" align="left">
                FAQ
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography component="h1" align="left" variant="body1">
                Find everything you need to distribute the game and support young people.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      { sections.map((title, index) => (
        <Grid key={ index } container direction="column">
          <Typography className={ classes.title } align='left' key={ index } color='textSecondary'>
            { title.title }
          </Typography>
          { title.faqs.map((question, index) => (
              <QuestionItem key={ index } question={ question.question } answer={ question.answer } />
            ),
          ) }

        </Grid>
      )) }
      <Footer />
    </Container>
  );
}
