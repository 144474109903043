import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import { catchAxios } from '../../../services/networkRequest';
import {
  disabilityParser,
  disabilityUnparser,
  formatDate,
  genderParser,
  genderUnparser,
  genericAlert,
  getChain,
} from '../../../services/helpers';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import { checkPostcodeValidationRequired, countryList, helperTextColor } from '../../../constants';
import RequiredInfoText from '../../RequiredInfoText';
import Error from '../../Error';
import { HelperText, InstructionTitle, Title } from '../../Title';
import { useViewport } from '../../../utilities/viewport';
import ExitButton from '../../ExitButton';
import queryString from 'query-string';

const phoneUtil = PhoneNumberUtil.getInstance();

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  disabilitySpace: {
    marginTop: '10px',
  },
  card: {
    minWidth: 275,
  },
  helperText: {
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
  },
  submitButton: {
    float: 'center',
    padding: '0.75rem 3rem',
  },
  submitButtonSelfSignUp: {
    float: 'center',
    padding: '0.75rem 3rem',
    background: '#CA218C',
    '&:hover': {
      background: '#9c1169',
    }
  },
  gameLogo: {
    width: '10rem',
    cursor: 'pointer',
  },
}));

export default function YoungPersonForm({ youngPerson,
                                          title,
                                          history,
                                          entitySettings,
                                          onSubmitForm,
                                          onError,
                                          location,
                                        }) {
  const classes = useStyles();
  const {
    sur,
    f,
    l,
    d,
    g,
  } = queryString.parse(location.search);

  // Get the values from the history state for if the back button has been selected on the confirmation page
  const editValues = history.location.state;
  const childEditValues = getChain(editValues, 'child');
  const guardianEditValues = getChain(editValues, 'guardian');

  // Split out the guardian relationship for the form inputs - note that this doesn't come from the backend currently.
  let guardianRelationship = getChain(youngPerson, 'guardians', 0, 'relationship') || getChain(guardianEditValues, 'relationship') || '';
  const guardianRelationshipOther = guardianRelationship && guardianRelationship !== 'Parent' && guardianRelationship !== 'Other' ? guardianRelationship : '';
  if (guardianRelationshipOther) {
    guardianRelationship = 'Other';
  }
  // Convert the date format to the one expected by the form.
  let dob = getChain(youngPerson, 'dob') || getChain(childEditValues, 'dob') || d || '';
  if (dob) {
    dob = formatDate(new Date(dob));
  }

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const confirmation = callback => {
    genericAlert('Cancel Young Person Form', 'All unsaved data will be lost', callback);
  };

  const country = getChain(youngPerson, 'country') || 'United Kingdom';
  let [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(country);

  const { views: { mobile, tablet } } = useViewport();
  const standardComponentXS = mobile ? 12 : 6;

  return (
    <Container component="main" maxWidth="lg">
      <Card className={ classes.card }>
        <CardContent>
          {
            entitySettings &&
            <Grid container justify="center">
              <img src="/ln_logo_transparent_background.png" alt="Lumi Nova logo" className={ classes.gameLogo } />
            </Grid>
          }
          <Formik
            initialValues={ {
              child: {
                first_name: getChain(youngPerson, 'first_name') || getChain(childEditValues, 'first_name') || f || '',
                last_name: getChain(youngPerson, 'last_name') || getChain(childEditValues, 'last_name') || l ||'',
                dob: dob,
                ethnicity: getChain(youngPerson, 'ethnicity') || getChain(childEditValues, 'ethnicity') || '',
                disability: disabilityParser(getChain(youngPerson, 'has_disability')) || disabilityParser(getChain(childEditValues, 'disability')),
                gender: genderParser(getChain(youngPerson, 'gender') || getChain(childEditValues, 'gender') || g || ''),
                post_code: getChain(youngPerson, 'post_code') || getChain(childEditValues, 'post_code'),
                country: getChain(youngPerson, 'country') || getChain(childEditValues, 'country') || 'United Kingdom',
                service_user_reference: getChain(youngPerson, 'service_user_reference') || getChain(childEditValues, 'service_user_reference') || sur,
                test_account: getChain(youngPerson, 'test_account') || getChain(childEditValues, 'test_account') || false,
              },
              guardian: {
                id: getChain(youngPerson, 'guardians', 0, 'id') || undefined,
                email: getChain(youngPerson, 'guardians', 0, 'email') || getChain(guardianEditValues, 'email') || '',
                telephone: getChain(youngPerson, 'guardians', 0, 'telephone') || getChain(guardianEditValues, 'telephone') || '',
                relationship: guardianRelationship,
                relationship_other: guardianRelationshipOther,
                first_name: getChain(youngPerson, 'guardians', 0, 'first_name') || getChain(guardianEditValues, 'first_name') || '',
                last_name: getChain(youngPerson, 'guardians', 0, 'last_name') || getChain(guardianEditValues, 'last_name') || '',
                post_code: getChain(youngPerson, 'guardians', 0, 'post_code') || getChain(guardianEditValues, 'post_code') || '',
              },
            } }

            validate={ values => {
              const errors = {};
              if (!entitySettings && values.guardian.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.guardian.email)) {
                errors.email = 'Invalid email address';
              }
              if (!values.child.first_name) {
                errors.childFirstName = 'Please specify the first name';
              }
              if (!values.child.last_name) {
                errors.childLastName = 'Please specify the last name';
              }
              if (!values.child.dob) {
                errors.dob = 'Please specify date of birth';
              }
              if (!values.guardian.telephone) {
                errors.number = 'Please specify number';
              } else {
                try {
                  const number = phoneUtil.parseAndKeepRawInput(values.guardian.telephone, 'GB');
                  if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                    errors.number = 'Please enter a valid number (with international dialling code if not +44)';
                  }
                } catch (err) {
                  errors.number = 'Please enter a valid number (with international dialling code if not +44)';
                }
              }
              if (!values.guardian.first_name) {
                errors.guardianFirstName = 'Please specify Guardian\'s First Name';
              }
              if (!values.guardian.last_name) {
                errors.guardianLastName = 'Please specify Guardian\'s Last Name';
              }
              if (!values.guardian.relationship) {
                errors.relationship = 'Please specify Relationship';
              }

              [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(values.child.country);

              if (values.guardian.post_code) {
                values.guardian.post_code = values.guardian.post_code.toUpperCase();
              }
              if (values.child.post_code) {
                values.child.post_code = values.child.post_code.toUpperCase();
              }

              return errors;
            } }
            onSubmit={ (values, { setSubmitting, setFieldError, resetForm }) => {
              setSubmitting(true);
              // Reverse date format for the backend
              const reformattedDoB = formatDate(values.child.dob);
              // Convert phone number into international format
              const number = phoneUtil.parseAndKeepRawInput(values.guardian.telephone, 'GB');
              const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
              const relationship = values.guardian.relationship === 'Other' && values.guardian.relationship_other ? values.guardian.relationship_other : values.guardian.relationship;
              const updatedValues = update(values, {
                child: {
                  dob: { $set: reformattedDoB },
                  disability: { $set: disabilityUnparser(values.child.disability) },
                  gender: { $set: genderUnparser(values.child.gender) },
                },
                guardian: {
                  telephone: { $set: internationalNumber },
                  relationship: { $set: relationship },
                },
              });

              onSubmitForm(updatedValues)
                .then(resetForm)
                .catch(err => {
                  const errorResponse = getChain(err, 'response', 'data');

                  if (entitySettings) {
                    onError(errorResponse);
                  } else if (getChain(err, 'response', 'status') === 402) {
                    const { title, body: message } = errorResponse;

                    genericAlert({
                      title,
                      message,
                      negativeTitle: 'Back',
                    });
                  } else {
                    catchAxios(setFieldError)(err);
                    setSubmitting(false);
                  }

                });
            } }
          >
            { ({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
                 /* and other goodies */
               }) => {
              return (
                <form onSubmit={ handleSubmit } autoComplete="off">
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    {
                      !entitySettings &&
                      <div style={ { display: 'grid', placeItems: 'end', width: '100%' } }>
                        <ExitButton history={ history } confirmation={ confirmation } />
                      </div>
                    }
                    <Grid item xs={ 12 }>
                      <Title>{ title }</Title>
                    </Grid>
                  </Grid>
                  <RequiredInfoText align="left" />
                  <InstructionTitle part={ 1 }>
                    Young Person Information
                  </InstructionTitle>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label="Young Person First Name"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="child.first_name"
                        autoFocus
                        onBlur={ handleBlur }
                        value={ values.child.first_name }
                        helperText={ errors.childFirstName && touched.child && touched.child.first_name && errors.childFirstName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                        disabled={ f }
                      />
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label="Young Person Last Name"
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.last_name"
                        onBlur={ handleBlur }
                        value={ values.child.last_name }
                        helperText={ errors.childLastName && touched.child && touched.child.last_name && errors.childLastName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                        disabled={ l }
                      />
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label="Young Person Date of Birth"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={ {
                          shrink: true,
                        } }
                        type="date"
                        name="child.dob"
                        onBlur={ handleBlur }
                        value={ values.child.dob }
                        helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                        disabled={ d }
                      />
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <FormControl variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="gender-select-outlined-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="gender-select-outlined-label"
                          id="gender-select-outlined"
                          name="child.gender"
                          value={ values.child.gender }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                          disabled={ g }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={ 'Male' }>Male</MenuItem>
                          <MenuItem value={ 'Female' }>Female</MenuItem>
                          <MenuItem value={ 'n/a' }>Prefer not to say</MenuItem>
                        </Select>
                        <HelperText>
                          { errors.gender && touched.child && touched.child.gender && errors.gender }
                        </HelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <FormControl variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="ethnicity-select-outlined-label">
                          Ethnicity
                        </InputLabel>
                        <Select
                          labelId="ethnicity-select-outlined-label"
                          id="ethnicity-select-outlined"
                          name="child.ethnicity"
                          value={ values.child.ethnicity }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={ 'white' }>White</MenuItem>
                          <MenuItem value={ 'mixed/multiple ethnic groups' }>Mixed/Multiple ethnic groups</MenuItem>
                          <MenuItem value={ 'asian/asian british' }>Asian/Asian British</MenuItem>
                          <MenuItem value={ 'black/african/caribbean/black british' }>Black/African/Caribbean/Black
                            British</MenuItem>
                          <MenuItem value={ 'chinese' }>Chinese</MenuItem>
                          <MenuItem value={ 'arab' }>Arab</MenuItem>
                          <MenuItem value={ 'other ethnic group' }>Other ethnic group</MenuItem>
                        </Select>
                        <HelperText>
                          { errors.ethnicity && touched.child && touched.child.ethnicity && errors.ethnicity }
                        </HelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ mobile ? 6 : 3 }>
                      <FormControl variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="country-select-outlined-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="country-select-outlined-label"
                          id="country-select-outlined"
                          name="child.country"
                          value={ values.child.country }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          {
                            countryList.map((country, i) =>
                              <MenuItem value={ country } key={ i }>{ country }</MenuItem>,
                            ) }
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ mobile ? 6 : 3 }>
                      <TextField
                        className={ classes.textField }
                        inputProps={ postcodeValidation }
                        label="Postcode"
                        required={ postcodeValidationRequired }
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.post_code"
                        onBlur={ handleBlur }
                        value={ values.child.post_code }
                        onChange={ handleChange }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ standardComponentXS } className={ classes.disabilitySpace }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <FormLabel component="legend">Has the young person got a disability? (Optional)</FormLabel>
                        <RadioGroup
                          aria-label="disability"
                          name="child.disability"
                          value={ values.child.disability }
                          onChange={ handleChange }>
                          <FormControlLabel value={ 'true' } control={ <Radio /> } label="Yes" />
                          <FormControlLabel value={ 'false' } control={ <Radio /> } label="No" />
                          <FormControlLabel value={ 'n/a' } control={ <Radio /> } label="Prefer not to say" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                      {
                        (!entitySettings || getChain(entitySettings, 'service_user_reference_field', 'show')) &&
                        <TextField
                          className={ classes.textField }
                          label={ getChain(entitySettings, 'service_user_reference_field', 'custom_title') || 'Service User Reference' }
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="text"
                          name="child.service_user_reference"
                          onBlur={ handleBlur }
                          value={ values.child.service_user_reference }
                          onChange={ handleChange }
                          disabled={ sur }
                          required={ true }
                        />
                      }
                    </Grid>
                  </Grid>

                  <InstructionTitle part={ 2 }>
                    Parent/Guardian Information
                  </InstructionTitle>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label="Guardian First Name"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="guardian.first_name"
                        onBlur={ handleBlur }
                        value={ values.guardian.first_name }
                        helperText={ errors.guardianFirstName && touched.guardian && touched.guardian.first_name && errors.guardianFirstName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label="Guardian Last Name"
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="guardian.last_name"
                        onBlur={ handleBlur }
                        value={ values.guardian.last_name }
                        helperText={ errors.guardianLastName && touched.guardian && touched.guardian.last_name && errors.guardianLastName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required={ !entitySettings }
                        type="email"
                        fullWidth
                        id="email"
                        label="Guardian Email Address"
                        name="guardian.email"
                        autoComplete="email"
                        value={ values.guardian.email }
                        helperText={ errors.email && touched.guardian && touched.guardian.email && errors.email }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        onBlur={ handleBlur }
                      />
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        inputProps={ postcodeValidation }
                        label="Guardian Postcode (if different from Young Person)"
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="guardian.post_code"
                        onBlur={ handleBlur }
                        value={ values.guardian.post_code }
                        onChange={ handleChange }
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <FormControl component="fieldset" className={ classes.formControl }>
                      <FormLabel component="legend">Relationship to Young Person *</FormLabel>
                      <Grid container alignItems="flex-end">
                        <Grid item xs={ mobile ? 3 : tablet ? 2 : 1 }>
                          <RadioGroup
                            aria-label="relationship"
                            name="guardian.relationship"
                            value={ values.guardian.relationship }
                            onChange={ handleChange }
                            required={ true }>
                            <FormControlLabel value="Parent" control={ <Radio /> } label="Parent" />
                            <FormControlLabel value="Other" control={ <Radio /> } label="Other" />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={ mobile ? 8 : 4 } style={ { padding: '8px' } }>
                          <TextField
                            variant="standard"
                            type="text"
                            name="guardian.relationship_other"
                            fullWidth
                            disabled={ (values.guardian.relationship !== 'Other') }
                            onBlur={ handleBlur }
                            value={ values.guardian.relationship_other }
                            onChange={ handleChange }
                          />
                        </Grid>
                      </Grid>
                    </FormControl>

                    <Grid container style={ { marginTop: '20px' } }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        {
                          entitySettings &&
                          <FormLabel component="legend" style={ { textAlign: 'left' } }>
                            Please provide your mobile number. We'll use this to send you an SMS to get started and notify
                            you about game play progress.
                          </FormLabel>
                        }
                        <Grid item xs={ standardComponentXS }>
                          <TextField
                            className={ classes.textField }
                            label="Guardian Mobile Number"
                            margin="normal"
                            variant="outlined"
                            type="tel"
                            name="guardian.telephone"
                            onBlur={ handleBlur }
                            value={ values.guardian.telephone }
                            helperText={ errors.number && touched.guardian && touched.guardian.telephone && errors.number }
                            FormHelperTextProps={ { style: { color: helperTextColor } } }
                            onChange={ handleChange }
                            required={ true }
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    {
                      !entitySettings &&
                      <Grid>
                        <FormControlLabel
                          className={ classes.formControl }
                          value="TestAccount"
                          control={
                            <Checkbox
                              color="primary"
                              inputProps={ { 'aria-label': 'secondary checkbox' } }
                              name="child.test_account"
                              value={ values.child.test_account }
                              checked={ values.child.test_account }
                              onChange={ handleChange }
                            />
                          }
                          label="Tick this box if this is a test account.  Test accounts still use a license but are not included in reporting." />
                      </Grid>
                    }

                    <HelperText>
                      { errors.relationship && touched.guardian && touched.guardian.relationship && errors.relationship }
                    </HelperText>
                  </Grid>

                  <Grid container spacing={ 2 } style={ { marginTop: '2rem' } }>
                    <Grid item xs={ 12 }>
                      {
                        entitySettings ?
                          <Button variant="contained" color="secondary" type="primary" disabled={ isSubmitting }
                                  className={ classes.submitButtonSelfSignUp }>
                            SUBMIT
                          </Button> :
                          <Button variant="contained" color="primary" type="primary" disabled={ isSubmitting }
                                  className={ classes.submitButton }>
                            SAVE
                          </Button>
                      }
                    </Grid>
                    <Grid item xs={ 12 }>
                      <Error style={ { textAlign: 'left' } } message={ errors['network'] } />
                    </Grid>
                  </Grid>
                </form>
              );
            } }
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}
