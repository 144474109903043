import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../../themes';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ChevronRight from '@material-ui/icons/ChevronRight';
import MaterialTable from 'material-table';
import { axios, CancelToken } from '../../../services/networkRequest';
import GameProgress from './YoungPersonComponents/GameProgress';
import ContactDetails from './YoungPersonComponents/ContactDetails';
import Warning from '@material-ui/icons/Warning';
import Edit from '@material-ui/icons/Edit';
import style, {
  activeColor,
  dropdownTextColor,
  greyBorderColor,
  listBackgroundColor,
  notActiveColor,
  numberTextColor,
  tableItemBorderColor,
} from '../../../Styles';
import { genericAlert, getChain, identity } from '../../../services/helpers';
import * as PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Timer from '@material-ui/icons/Timer';
import Footer from '../../content/Footer';
import UsageGraph from '../UsageGraph';
import { addMonths, addDays } from 'date-fns';


const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: '4px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  table: {
    marginTop: '20px',
  },
  pos: {
    marginBottom: 12,
  },
  center: {
    display: 'block',
    margin: '0 auto',
  },
  left: {
    display: 'inline-block',
    textAlign: 'left',
    float: 'left',
    marginTop: '8px',
  },
  button: {
    display: 'inline-block',
    textAlign: 'right',
    float: 'right',
    marginBottom: '16px',
    '&:hover': {
      backgroundColor: '#0079C6',
    },
    '&:active': {
      backgroundColor: 'white',
      borderColor: 'white',
      color: '#0079C6',
    },
  },
  block: {
    display: 'block',
    width: '100%',
  },
  tableItem: {
    border: `1px solid ${ tableItemBorderColor }`,
    marginTop: '10px',
  },
  greenBorder: {
    borderLeft: `10px solid ${ activeColor }`,
  },
  paddedItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  text: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: '50px',
  },
  textSpace: {
    paddingTop: '20px',
  },
  buttonSpace: {
    paddingTop: '40px',
  },
  deactivateButton: {
    marginLeft: 'auto',
    color: '#3B3B3B',
    textDecoration: 'underline',
  },
  youngPersonActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  select: {
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '14px',
    padding: '2px 10px',
    minWidth: '160px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none'
    }
  }
});

const order = new Proxy({
  'complete': 1,
  'started': 2,
  'incomplete': 3,
}, {
  get: (target, key) => target[key] || 4,
});

export const setter = (list, updater) => (rowData) => {
  const index = list.findIndex(yp => yp.id === rowData.id);
  if (index > -1) {
    // shallow copy is need for react to know we changed the state or a re-render doesn't trigger
    const newList = [...list];
    newList[index] = rowData;
    updater(newList);
  }
};

export default function GamersPage(props) {
  const classes = useStyles();
  const [youngPersons, setYoungPersons] = useState(undefined);
  const [notStartedYoungPersons, setNotStartedYoungPersons] = useState(undefined);
  const [optedOutYoungPersons, setOptedOutYoungPersons] = useState(undefined);
  const [gameKeys, setGameKeys] = useState({ alert: false, totalKeys: 0, usedKeys: 0 });
  const [allPeople, setAllPeople] = useState(undefined);
  const [dataState, setDataState] = useState(false);

  const reloadData = () => {
    setDataState(!dataState);
    setAllPeople(undefined);
    setYoungPersons(undefined);
    setNotStartedYoungPersons(undefined);
    setOptedOutYoungPersons(undefined);
  };

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    axios.get('/ln/young_persons', {
      cancelToken,
    }).then(({ data }) => {
      setAllPeople(data.young_persons.active.length + data.young_persons.not_yet_active.length + data.young_persons.inactive.length);
      setYoungPersons(data.young_persons.active);
      setNotStartedYoungPersons(data.young_persons.not_yet_active);
      setOptedOutYoungPersons(data.young_persons.inactive);
      setGameKeys({ alert: data.alert, totalKeys: data.total_keys, usedKeys: data.used_keys });
    }).catch(identity);

    return () => {
      cancel();
    };
  }, [dataState]);

  const renderZeroState = () => {
    return (
      <Container component="main" maxWidth="lg">
        <Card className={ classes.card }>
          <CardContent>
            <Grid container className={ classes.text } direction='column' alignItems='center' justify='center'>
              <Grid item xs={ 6 }>
                <Typography component="h5" align="center" variant="h5" style={ { fontWeight: 800 } }>
                  Invite a young person to get started
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } justify='center' className={ classes.buttonSpace }>
              <div>
                <Button variant="contained" color="primary"
                        className={ classes.button }
                        onClick={ () => {
                          props.history.push('/ln/young_person/create');
                        } }>
                  INVITE YOUNG PERSON
                </Button>
              </div>
            </Grid>
            <div style={ { margin: '25px -50px', borderTop: `${ numberTextColor } solid 4px` } } />
            <CardMedia image="/GettingStartedLn.jpg" title="" style={ { height: 700 } } />
          </CardContent>
        </Card>
        <Footer />
      </Container>
    );
  };

  const RenderDetailFactory = (borderColour, setter) => (rowData) => <RenderDetails
    setYoungPersonData={ setter }
    borderColour={ borderColour }
    reloadData={ reloadData }
    rowData={ rowData } { ...props } />;

  const renderList = props => {
    const demoUsers = [{
      'counts': { 'challenges': 14, 'goals': 2 },
      'created_at': 'Mon, 25 May 2020 09:25:55 GMT',
      'deleted_at': null,
      'dob': 'Tue, 25 May 2010 00:00:00 GMT',
      'ethnicity': 'asian/asian british',
      'first_name': 'Demo',
      'first_play': 'Mon, 25 May 2020 09:29:53 GMT',
      'game_key': 'demo-test-user',
      'gender': 'Female',
      'guardians': [{
        'created_at': 'Thu, 21 May 2020 12:58:52 GMT',
        'email': 'contact@bfb-labs.com',
        'first_name': 'Demo',
        'id': null,
        'last_name': 'Parent',
        'post_code': '',
        'relationship': 'Parent',
        'telephone': '+447400000000',
        'updated_at': 'Thu, 21 May 2020 12:58:52 GMT',
      }],
      'has_disability': false,
      'id': null,
      'last_name': 'User',
      'post_code': 'EC1M 4NJ',
      'updated_at': 'Mon, 25 May 2020 09:25:55 GMT',
      'tableData': { 'id': 8 },
      'usage_data': {},
      'stats': {
        'game_time': {
          'lastLoggedIn': 'Wed, 10 Jun 2020 23:51:00 GMT',
          'timeAverage': 15.01,
          'timeSpent': 150.1,
          'totalSessions': 10,
        }, 'progress': [{
          'inGame': [{
            'attempted': 1,
            'complete': true,
            'name': 'Imagine yourself being home without your guardian',
            'reflections': [{
              'date': '2020-05-25',
              'results': [{ 'score': 3, 'time': 1 }, { 'score': 2, 'time': 2 }, { 'score': 1, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 0,
          }, {
            'attempted': 1,
            'complete': true,
            'name': 'Draw yourself at home without your guardian',
            'reflections': [{
              'date': '2020-05-26',
              'results': [{ 'score': 2, 'time': 1 }, { 'score': 1, 'time': 2 }, { 'score': 3, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 1,
          }, {
            'attempted': 2,
            'complete': true,
            'name': 'Watch a 3D video of being home without a guardian',
            'reflections': [{
              'date': '2020-05-27',
              'results': [{ 'score': 2, 'time': 1 }, { 'score': 3, 'time': 2 }, { 'score': 1, 'time': 3 }],
            }, {
              'date': '2020-05-29',
              'results': [{ 'score': 3, 'time': 1 }, { 'score': 1, 'time': 2 }, { 'score': 4, 'time': 3 }],
            }],
            'repeated': 1,
            'step': 2,
          }],
          'outOfGame': [{
            'attempted': 1,
            'complete': true,
            'name': 'Stay in a room alone without guardian',
            'reflections': [{
              'date': '2020-05-28',
              'results': [{ 'score': 3, 'time': 1 }, { 'score': 2, 'time': 2 }, { 'score': 4, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 3,
          }, {
            'attempted': 1,
            'complete': true,
            'name': 'Stay in the house alone with guardian outside on the phone',
            'reflections': [{
              'date': '2020-05-30',
              'results': [{ 'score': 1, 'time': 1 }, { 'score': 4, 'time': 2 }, { 'score': 3, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 4,
          }, {
            'attempted': 1,
            'complete': true,
            'name': 'Stay in the house while guardian goes outside',
            'reflections': [{
              'date': '2020-05-31',
              'results': [{ 'score': 4, 'time': 1 }, { 'score': 3, 'time': 2 }, { 'score': 2, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 5,
          }, {
            'attempted': 1,
            'complete': true,
            'name': 'Stay home with someone else without guardian for 1 hour',
            'reflections': [{
              'date': '2020-06-01',
              'results': [{ 'score': 1, 'time': 1 }, { 'score': 2, 'time': 2 }, { 'score': 3, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 6,
          }, {
            'attempted': 1,
            'complete': true,
            'name': 'Stay with someone else without guardian for evening',
            'reflections': [{
              'date': '2020-06-02',
              'results': [{ 'score': 5, 'time': 1 }, { 'score': 4, 'time': 2 }, { 'score': 1, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 7,
          }],
          'status': 'complete',
          'title': 'Be comfortable staying at home without their guardian',
        }, {
          'inGame': [{
            'attempted': 1,
            'complete': true,
            'name': 'Imagine yourself eating or drinking in front of others',
            'reflections': [{
              'date': '2020-06-02',
              'results': [{ 'score': 5, 'time': 1 }, { 'score': 2, 'time': 2 }, { 'score': 3, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 0,
          }, {
            'attempted': 1,
            'complete': true,
            'name': 'Draw yourself eating or drinking in front of others',
            'reflections': [{
              'date': '2020-06-01',
              'results': [{ 'score': 2, 'time': 1 }, { 'score': 2, 'time': 2 }, { 'score': 2, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 1,
          }, {
            'attempted': 1,
            'complete': true,
            'name': 'Watch a video of eating loudly in front of others',
            'reflections': [{
              'date': '2020-06-02',
              'results': [{ 'score': 2, 'time': 1 }, { 'score': 2, 'time': 2 }, { 'score': 2, 'time': 3 }],
            }],
            'repeated': 0,
            'step': 2,
          }],
          'outOfGame': [{
            'attempted': 0,
            'complete': false,
            'name': 'Have a small snack with a friend or family member',
            'reflections': [],
            'repeated': 0,
            'step': 3,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Eat a loud, smelly, or messy food with a friend or family member',
            'reflections': [],
            'repeated': 0,
            'step': 4,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Have a small snack with a few friends or family members',
            'reflections': [],
            'repeated': 0,
            'step': 5,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Eat a loud, smelly, or messy food with a few friends or family members',
            'reflections': [],
            'repeated': 0,
            'step': 6,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Have a small snack in a public place',
            'reflections': [],
            'repeated': 0,
            'step': 7,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Eat a loud, smelly, or messy food in a public place',
            'reflections': [],
            'repeated': 0,
            'step': 8,
          }],
          'status': 'started',
          'title': 'Be able to eat or drink in front of other people',
        }, {
          'inGame': [{
            'attempted': 0,
            'complete': false,
            'name': 'Imagine yourself going to a party',
            'reflections': [],
            'repeated': 0,
            'step': 0,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Draw yourself in a high up place',
            'reflections': [],
            'repeated': 0,
            'step': 1,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Look at photos of people in high up places',
            'reflections': [],
            'repeated': 0,
            'step': 2,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Watch a video of looking down from a high up place',
            'reflections': [],
            'repeated': 0,
            'step': 3,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Watch a 3D video in a very high up place',
            'reflections': [],
            'repeated': 0,
            'step': 4,
          }],
          'outOfGame': [{
            'attempted': 0,
            'complete': false,
            'name': 'Go somewhere quite high up',
            'reflections': [],
            'repeated': 0,
            'step': 5,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Go somewhere quite high up and look down',
            'reflections': [],
            'repeated': 0,
            'step': 6,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Go somewhere very high up',
            'reflections': [],
            'repeated': 0,
            'step': 7,
          }, {
            'attempted': 0,
            'complete': false,
            'name': 'Go somewhere very high up and look down',
            'reflections': [],
            'repeated': 0,
            'step': 8,
          }],
          'status': 'incomplete',
          'title': 'Be able to spend time in a high up place',
        }],
      },
    }];

    const today = new Date();
    today.setDate(1);
    let start = today
    let month = start.getMonth();
    const firstMonthKey = JSON.stringify({
      month,
      year: start.getFullYear(),
    });
    start = addMonths(start, 1);
    start.setDate(0);
    const monthLength = start.getDate();
    start.setDate(1);

    const firstMonth = [];
    let i = 0;
    for(; i < monthLength - 7; i++) {
      firstMonth.push({
        date: addDays(start, i).toDateString(),
        time: 0,
      });
    }

    firstMonth.push(
      { date: addDays(start, i).toDateString(), time: 1728 },
      { date: addDays(start, i + 1).toDateString(), time: 917, },
      { date: addDays(start, i + 2).toDateString(), time: 455 },
      { date: addDays(start, i + 3).toDateString(), time: 1368, },
      { date: addDays(start, i + 4).toDateString(), time: 598 },
      { date: addDays(start, i + 5).toDateString(), time: 669, },
      { date: addDays(start, i + 6).toDateString(), time: 828 },
    );

    start = addMonths(start, 1);
    start.setDate(1);

    month = start.getMonth();

    const secondMonthKey = JSON.stringify({
      month,
      year: start.getFullYear(),
    });
    const secondMonth = [
      {
        date: start.toDateString(),
        time: 653,
      },
      {
        date: addDays(start, 1).toDateString(),
        time: 1581,
      },
      {
        date: addDays(start, 2).toDateString(),
        time: 0,
      },
      {
        date: addDays(start, 3).toDateString(),
        time: 0,
      },
      {
        date: addDays(start, 4).toDateString(),
        time: 0,
      },
      {
        date: addDays(start, 5).toDateString(),
        time: 0,
      },
      {
        date: addDays(start, 6).toDateString(),
        time: 0,
      },
      {
        date: addDays(start, 7).toDateString(),
        time: 209,
      },
    ];
    for(let i = 8, currentDate = addDays(start, i); month === currentDate.getMonth(); i++, currentDate = addDays(start, i)) {
      secondMonth.push({
        date: currentDate.toDateString(),
        time: 0,
      });
    }

    demoUsers[0]['usage_data'][firstMonthKey] = firstMonth;
    demoUsers[0]['usage_data'][secondMonthKey] = secondMonth;

    return (
      <Container component="main" maxWidth="lg" style={ { marginTop: '10px' } }>
        <Card className={ classes.card }>
          <CardContent>
            <div className={ classes.block }>
              <Typography className={ classes.left } color="textSecondary">
                <strong>Total Licenses Used&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{ gameKeys.alert ?
                <Warning htmlColor='black' style={ {
                  height: '0.7em',
                  marginBottom: '-0.12em',
                } } /> : null }{ ` ${ gameKeys.usedKeys } out of ${ gameKeys.totalKeys }` }
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={ classes.button }
                onClick={ () => props.history.push('/ln/young_person/create') }>
                INVITE YOUNG PERSON
              </Button>
            </div>
          </CardContent>
        </Card>
        <ThemeProvider theme={ tableThemer(youngPersons) }>
          <MaterialTable
            isLoading={ !youngPersons }
            columns={ [
              {
                title: 'PLAYING THE GAME',
                field: 'first_name',
                cellStyle: {
                  borderLeft: `20px solid ${ activeColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
              },
              {
                title: 'INVITED',
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
              },
              {
                title: 'STARTED GAME',
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
              },
              {
                title: 'CHALLENGES',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ getChain(rowData, 'counts', 'challenges') || 0 }</Typography>,
              },
              {
                title: 'GOALS',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ getChain(rowData, 'counts', 'goals') || 0 }</Typography>,
              },
              {
                title: 'GAME KEY',
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
              },
            ] }
            data={ youngPersons }
            detailPanel={ RenderDetailFactory(activeColor, setter(youngPersons, setYoungPersons)) }
            onRowClick={ (event, rowData, togglePanel) => togglePanel() }
            options={ {
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
        <ThemeProvider theme={ tableThemer(notStartedYoungPersons) }>
          <MaterialTable
            isLoading={ !notStartedYoungPersons }
            columns={ [
              {
                title: 'NOT YET STARTED',
                field: 'first_name',
                cellStyle: {
                  borderLeft: `20px solid ${ notActiveColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
              },
              {
                title: 'INVITED',
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
              },
              {
                title: 'STARTED GAME',
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
              },
              {
                title: 'GAME KEY',
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
              },
            ] }
            data={ notStartedYoungPersons }
            detailPanel={ RenderDetailFactory(notActiveColor, setter(notStartedYoungPersons, setNotStartedYoungPersons)) }
            onRowClick={ (event, rowData, togglePanel) => togglePanel() }
            options={ {
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
        <ThemeProvider theme={ tableThemer(optedOutYoungPersons) }>
          <MaterialTable
            isLoading={ !optedOutYoungPersons }
            columns={ [
              {
                title: 'OPTED OUT',
                field: 'first_name',
                cellStyle: {
                  borderLeft: `20px solid ${ greyBorderColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
              },
              {
                title: 'INVITED',
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
              },
              {
                title: 'STARTED GAME',
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
              },
              {
                title: 'GAME KEY',
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
              },
            ] }
            data={ optedOutYoungPersons }
            detailPanel={ RenderDetailFactory(greyBorderColor, setter(optedOutYoungPersons, setOptedOutYoungPersons)) }
            onRowClick={ (event, rowData, togglePanel) => togglePanel() }
            options={ {
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
        <ThemeProvider theme={ tableThemer(demoUsers) }>
          <MaterialTable
            isLoading={ !demoUsers }
            columns={ [
              {
                title: 'DEMO USER',
                field: 'first_name',
                cellStyle: {
                  borderLeft: `20px solid ${ dropdownTextColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
              },
              {
                title: 'INVITED',
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
              },
              {
                title: 'STARTED GAME',
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
              },
              {
                title: 'CHALLENGES',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ getChain(rowData, 'counts', 'challenges') || 0 }</Typography>,
              },
              {
                title: 'GOALS',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ getChain(rowData, 'counts', 'goals') || 0 }</Typography>,
              },
              {
                title: 'GAME KEY',
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
              },
            ] }
            data={ demoUsers }
            detailPanel={ RenderDetailFactory(dropdownTextColor, setter(demoUsers, identity)) }
            onRowClick={ (event, rowData, togglePanel) => togglePanel() }
            options={ {
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
        <Footer />
      </Container>
    );
  };

  return (
    <>
      { allPeople === 0 ? renderZeroState() : renderList(props) }
    </>
  );
}

const sorting = progress => {
  return progress && progress.sort((a, b) => order[a.status] - order[b.status]);
};

export function RenderDetails(props) {
  const {
    rowData,
    setYoungPersonData,
    reloadData,
    borderColour,
  } = props;
  const classes = useStyles();
  const [userData, setUserData] = useState(rowData);

  useEffect(() => {
    if (!getChain(userData, 'stats')) {
      axios.get(`/ln/young_persons/${ userData.id }`)
        .then(({ data }) => {
          userData.stats = data;
          setUserData(userData);
          setYoungPersonData(userData);
        })
        .catch(error => {
          genericAlert('Error', getChain(error, 'response', 'data', 'error'));
        });
    }
  }, [userData, setYoungPersonData]);

  const { tableData, ...restUserData } = userData;
  const player = userData.first_name;
  const progress = sorting(getChain(userData, 'stats', 'progress'));
  const gameTime = getChain(userData, 'stats', 'game_time');
  const lastUsedDateString = gameTime && gameTime.lastLoggedIn && new Date(gameTime.lastLoggedIn).toLocaleDateString();
  const averageTime = gameTime ? gameTime.timeAverage : '';
  const totalTime = gameTime && gameTime.timeSpent;
  const totalSessions = gameTime && averageTime > 0 ? Math.round(totalTime / averageTime) : '';
  const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
  const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';

  return (
    <div style={ {
      backgroundColor: listBackgroundColor,
      position: 'relative',
      width: 'calc(100% + 1px)',
      borderLeft: `20px solid ${ borderColour }`,
      borderRight: `1px solid ${ greyBorderColor }`,
      boxSizing: 'border-box',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginTop: '-18px',
    } }
         key={ userData.id }
    >
      <Grid container style={ { width: 'inherit', margin: 0, paddingLeft: '30px', paddingTop: '30px' } }
            spacing={ 3 }>
        { userData.first_play && <>
          <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid item xs={ 6 }><Typography color="textPrimary">{ `${ player }'s game time` }</Typography></Grid>
                  <Grid item xs={ 6 }><Typography color="textSecondary"
                                                  align="right">{ `Last logged in: ${ lastUsedDateString || '' }` }</Typography></Grid>
                </Grid>
              </CardContent>
              {/* <WeeklyGameTime gameTime={ gameTime } firstName={ userData.first_name } /> */ }
            </Card>
          </Grid>
          <Grid item xs={ 4 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">Total game sessions</Typography>
                <Grid container>
                  <Grid item xs={ 10 }><Typography
                    style={ { fontSize: '30px', color: numberTextColor } }>{ totalSessions }</Typography></Grid>
                  <Grid item xs={ 2 }><GameAsset style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 4 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">Average time per session</Typography>
                <Grid container>
                  <Grid item xs={ 10 }>
                    <Typography style={ { fontSize: '30px', color: numberTextColor, marginRight: '2px' } }
                                component='span'>{ averageTime }</Typography>
                    <Typography color="textPrimary" component='span'>mins</Typography>
                  </Grid>
                  <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 4 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">Total time spent playing</Typography>
                <Grid container>
                  <Grid item xs={ 10 }>
                    <Typography style={ { fontSize: '30px', color: numberTextColor, marginRight: '2px' } }
                                component='span'>{ totalHours }</Typography>
                    <Typography color="textPrimary" style={ { marginRight: '8px' } } component='span'>hrs</Typography>
                    <Typography style={ { fontSize: '30px', color: numberTextColor, marginRight: '2px' } }
                                component='span'>{ totalMinutes }</Typography>
                    <Typography color="textPrimary" component='span'>mins</Typography>
                  </Grid>
                  <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <UsageGraph classes={classes} playerName={player} userData={userData} setUserData={(userData) => {
            setUserData(userData);
            setYoungPersonData(userData);
          } } chartUrl={`/ln/young_persons/${ userData.id }/chart`} />
          { progress && progress.length > 0 && <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textSecondary">{ `${ player }'s game progress` }</Typography>
                <GameProgress progress={ progress } />
              </CardContent>
            </Card>
          </Grid> }
        </> }
        <Grid item xs={ 12 }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Grid container>
                <Grid item xs={ 10 }>
                  <Typography color="textSecondary">CONTACT</Typography>
                </Grid>
                <Grid item xs={ 2 }>
                  <Grid container>
                    <Grid item xs={ 6 } style={ { marginLeft: 'auto' } }>
                      <Button
                        onClick={ () => {
                          const { dob, ethnicity, first_name, gender, guardians, has_disability, id, last_name, post_code, service_user_reference, country, test_account } = userData;
                          props.history.push(`/ln/young_person/${ id }/edit`, {
                            youngPerson: {
                              dob,
                              ethnicity,
                              first_name,
                              gender,
                              guardians,
                              has_disability,
                              id,
                              last_name,
                              post_code,
                              country,
                              service_user_reference,
                              test_account
                            },
                          });
                        } }
                      >
                        <Edit htmlColor={ greyBorderColor } />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ContactDetails { ...userData } started={progress && progress.length > 0} />
            </CardContent>
          </Card>
        </Grid>
        { userData.id &&
        <Grid item xs={ 12 }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Link to={ `/ln/survey_results/${ userData.id }` }>
                <Typography color="textSecondary" style={ { textDecoration: 'underline' } }>Goal Based Outcomes and
                  Guardian Survey answers (detailed view)</Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        }
        <Grid item className={ classes.deactivateButton }
              style={ { display: 'flex', justifyContent: 'space-between', width: '100%' } }>
          <Grid item xs={ 3 }>
            <ThemeProvider theme={ style }>
              <Button fullWidth variant="contained" color="primary"
                      onClick={ () => {
                        props.history.push(`/ln/young_person/${ userData.id }/report`, restUserData);
                      } }>
                DOWNLOAD REPORT
              </Button>
            </ThemeProvider>
          </Grid>
          { userData.deleted_at ?
            <RemovalButton classes={ classes } title="DELETE YOUNG PERSON" action={ () => {
              genericAlert({
                title: 'Are you sure you want to delete young person?',
                message: 'Deleting a young person will permanently remove all personal information and anonymise game data.',
                negativeTitle: 'Cancel',
                positiveTitle: 'Delete',
                positiveCallback: () => {
                  axios
                    .delete(`/ln/young_persons/${ userData.id }/anonymise`)
                    .then(reloadData);
                },
              });
            } } />
            : <RemovalButton classes={ classes } title="DEACTIVATE GAME KEY" action={ () => {
              axios.get('/ln/young_persons/deactivation_reason')
                .then(({ data }) => {
                  let reason;
                  const setReason = (r) => {
                    reason = r;
                  };
                  genericAlert({
                    title: 'Pick a deactivation reason',
                    component: () => <ReasonSelection data={ data } setReason={ setReason } />,
                    negativeTitle: 'Cancel',
                    positiveTitle: 'Submit',
                    positiveCallback: () => {
                      const { id } = userData;
                      if (id) {
                        axios.delete(`/ln/young_persons/${ id }`, {
                          data: {
                            reason,
                          },
                        })
                          .then(reloadData)
                          .catch(identity);
                      }
                    },
                  });
                });
            } }
            />
          }
        </Grid>
      </Grid>
    </div>
  );
}

const RemovalButton = ({ title, action, classes }) =>
  <Button className={ classes.deactivateButton } onClick={ action }>
    { title }
  </Button>;

RemovalButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

RenderDetails.propTypes = {
  borderColour: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
  setYoungPersonData: PropTypes.func.isRequired,
};

const ReasonSelection = ({ data, setReason }) => {
  const [internalReason, setInternalReason] = useState();
  return (
    <form>
      <FormControl component="fieldset">
        <FormLabel component="legend">Reason</FormLabel>
        <RadioGroup aria-label="reason" name='reason'
                    onChange={ event => {
                      let value = parseInt(event.target.value);
                      setReason(value);
                      setInternalReason(value);
                    } }>
          {
            data.map(each => <FormControlLabel key={ each.id } value={ each.id } control={ <Radio /> }
                                               label={ each.value } checked={ each.id === internalReason } />)
          }
        </RadioGroup>
      </FormControl>
    </form>
  );
};

ReasonSelection.propTypes = {
  data: PropTypes.array.isRequired,
  setReason: PropTypes.func.isRequired,
};
