import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { barColor } from '../Styles';
import { months } from '../constants';
import { tickSteps } from '../services/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 350,
  },
  centre: {
    margin: 'auto',
  },
}));


const monthValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const MonthlyYoungPeople = (props) => {
  const classes = useStyles();

  if (!props.data) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }
  // The data comes from the server in the correct order.
  // We have to get the month labels using the month numbers provided,
  // then re-number the months 1-12 to display in order on the axis.
  const monthOrder = props.data.map(each => {
      const date = new Date(each.date);
      return date.getMonth() + 1;
  });
  const monthStrings = monthOrder.map(each => months[each - 1]);

  const chartData = props.data.map((item, index) => {
    return {
      month: index + 1,
      users: item.count,
    };
  });

  const userValues = chartData.map(item => item.users);
  const tickValues = tickSteps(userValues);

  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 370 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ monthValues }
                     tickFormat={ monthStrings }
                     label='Month'
                     style={ { axisLabel: { padding: 30 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={ tickValues }
                     label='Young people playing the game'
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryBar
          tickValues={ monthValues }
          cornerRadius={ { top: 2 } }
          style={ { data: { fill: barColor, width: props.size.width / 20 } } }
          data={ chartData }
          x="month"
          y="users"
        />
      </VictoryChart>
    </div>
  );
};

export default withSize()(MonthlyYoungPeople);
