import React, { useState } from 'react';
import { axios, catchAxios } from '../../services/networkRequest';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { formatDate, genericAlert, getChain } from '../../services/helpers';
import * as PropTypes from 'prop-types';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import { gameKeyMax, helperTextColor } from '../../constants';
import Card from '@material-ui/core/Card';
import ExitButton from '../ExitButton';

const phoneUtil = PhoneNumberUtil.getInstance();

function OrganisationForm({ organisation, title, path, history }) {
  const method = organisation ? 'post' : 'put';
  const confirmation = callback => {
    genericAlert('Cancel Organisation Form', 'All unsaved data will be lost', callback);
  };
  const postcodePattern = '([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})';

  const [checkingPostcode, setCheckingPostcode] = useState(false);
  const [organisationWarning, setOrganisationWarning] = useState(false);
  const [currentPostcode, setCurrentPostcode] = useState();

  return (
    <Card>
      <ExitButton style={ { float: 'right', padding: '20px' } } history={ history } confirmation={ confirmation } />
      <Container component="main" maxWidth="xs" style={ { padding: '20px' } }>
        <CssBaseline />

        <Typography component="h1" variant="h5">
          { title }
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ {
            name: getChain(organisation, 'name'),
            postcode: getChain(organisation, 'postcode'),
            address: getChain(organisation, 'address'),
            phone: getChain(organisation, 'phone'),
            ln_game_keys: getChain(organisation, 'ln_game_keys') || 0,
            expiration_date: formatDate(getChain(organisation, 'ln_expiration_date')),
            cots_game_keys: getChain(organisation, 'cots_game_keys') || 0,
            cots_expiration_date: formatDate(getChain(organisation, 'cots_expiration_date')),
            buyer: {
              name: getChain(organisation, 'buyer', 'name'),
              email: getChain(organisation, 'buyer', 'email'),
            },
            point_person: {
              name: getChain(organisation, 'point_person', 'name'),
              phone: getChain(organisation, 'point_person', 'phone'),
              email: getChain(organisation, 'point_person', 'email'),
            },
          } }
          validate={ values => {
            const errors = {};
            if (!values.name) {
              errors.name = 'Please specify the organisation name';
            }

            if (!values.ln_game_keys && !values.cots_game_keys) {
              errors.ln_game_keys = 'Please specify how many game keys the organisation has purchased';
              errors.cots_game_keys = 'Please specify how many game keys the organisation has purchased';
            } else if (values.ln_game_keys < 0 || values.cots_game_keys < 0) {
              errors.ln_game_keys = values.ln_game_keys < 0 ? 'You must specify a positive number of game keys' : null;
              errors.cots_game_keys = values.cots_game_keys < 0 ? 'You must specify a positive number of game keys' : null;
            } else if (values.ln_game_keys > gameKeyMax || values.cots_game_keys > gameKeyMax) {
              values.ln_game_keys = values.ln_game_keys > gameKeyMax ? gameKeyMax : values.ln_game_keys;
              values.cots_game_keys = values.cots_game_keys > gameKeyMax ? gameKeyMax : values.cots_game_keys;
            }


            if (!values.postcode) {
              errors.postcode = 'Please specify the organisation postcode';
            } else {
              values.postcode = values.postcode.toUpperCase();
              if (!organisation && values.postcode !== currentPostcode && values.postcode.match(postcodePattern)) {
                setCheckingPostcode(true);
                axios.get(`/organisation/lookup/${ values.postcode }`)
                  .then(({ data }) => {
                    setOrganisationWarning(!!getChain(data, 'duplicate'));
                  })
                  .finally(() => {
                    setCurrentPostcode(values.postcode);
                    setCheckingPostcode(false);
                  });
              }
            }
            if (!values.address) {
              errors.address = 'Please specify the organisation address';
            }
            if (!values.phone) {
              errors.phone = 'Please specify a phone number';
            } else {
              try {
                const number = phoneUtil.parseAndKeepRawInput(values.phone, 'GB');
                if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                  errors.phone = 'Please enter a valid number (with international dialling code if not +44)';
                }
              } catch (err) {
                errors.phone = 'Please enter a valid number (with international dialling code if not +44)';
              }
            }
            if (!values.buyer.name) {
              errors.buyer = {};
              errors.buyer.name = 'Please specify the name of the buyer';
            }
            if (!values.buyer.email) {
              errors.buyer = errors.buyer || {};
              errors.buyer.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.buyer.email)
            ) {
              errors.buyer = errors.buyer || {};
              errors.buyer.email = 'Invalid email address';
            }
            if (!values.point_person.name) {
              errors.point_person = {};
              errors.point_person.name = 'Please specify the name of the point person';
            }
            if (!values.point_person.phone) {
              errors.point_person = errors.point_person || {};
              errors.point_person.phone = 'Please specify number';
            } else {
              try {
                const number = phoneUtil.parseAndKeepRawInput(values.point_person.phone, 'GB');
                if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                  errors.point_person = errors.point_person || {};
                  errors.point_person.phone = 'Please enter a valid number (with international dialling code if not +44)';
                }
              } catch (err) {
                errors.point_person = errors.point_person || {};
                errors.point_person.phone = 'Please enter a valid number (with international dialling code if not +44)';
              }
            }
            if (!values.point_person.email) {
              errors.point_person = errors.point_person || {};
              errors.point_person.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.point_person.email)
            ) {
              errors.point_person = errors.point_person || {};
              errors.point_person.email = 'Invalid email address';
            }
            //console.log(errors);
            return errors;
          } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            // Convert phone number into international format
            const number = phoneUtil.parseAndKeepRawInput(values.phone, 'GB');
            const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
            const number2 = phoneUtil.parseAndKeepRawInput(values.point_person.phone, 'GB');
            const internationalNumber2 = phoneUtil.format(number2, PhoneNumberFormat.E164);
            const updatedValues = update(values, {
              phone: { $set: internationalNumber },
              point_person: { phone: { $set: internationalNumber2 } },
            });
            const submitFunction = () => {
              axios[method](path, updatedValues)
                .then(() => {
                  history.replace('/');
                })
                .catch(err => {
                  catchAxios(setFieldError)(err);
                  setSubmitting(false);
                });
            };

            if (organisationWarning) {
              genericAlert(
                {
                  title: 'Duplicate Organisation Warning',
                  message: 'The postcode for this organisation already exists. Are you sure you want to submit this Organisation?',
                  positiveCallback: submitFunction,
                  positiveTitle: 'Yes, Submit',
                  negativeTitle: 'Go Back',
                });
            } else {
              submitFunction();
            }
          } }
        >
          { ({
               errors,
               values,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />
              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                label="Organisation Name"
                value={ values.name }
                required
                name='name'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.name && touched.name && errors.name }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                type="number"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Lumi Nova Licenses"
                value={ values.ln_game_keys }
                required
                name='ln_game_keys'
                inputProps={ { min: 0, max: gameKeyMax } }
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.ln_game_keys && touched.ln_game_keys && errors.ln_game_keys }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                inputProps={ {
                  min: formatDate(new Date()),
                } }
                fullWidth
                label="Lumi Nova Licenses Valid Until"
                margin="normal"
                variant="outlined"
                InputLabelProps={ {
                  shrink: true,
                } }
                type="date"
                name="expiration_date"
                onBlur={ handleBlur }
                value={ values.expiration_date }
                helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
                onChange={ handleChange }
                required={ true }
              />


              <TextField
                type="number"
                variant="outlined"
                margin="normal"
                fullWidth
                label="COTS Pro Licenses"
                value={ values.cots_game_keys }
                required
                name='cots_game_keys'
                inputProps={ { min: 0, max: gameKeyMax } }
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.cots_game_keys && touched.cots_game_keys && errors.cots_game_keys }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                inputProps={ {
                  min: formatDate(new Date()),
                } }
                fullWidth
                label="COTS Pro Licenses Valid Until"
                margin="normal"
                variant="outlined"
                InputLabelProps={ {
                  shrink: true,
                } }
                type="date"
                name="cots_expiration_date"
                onBlur={ handleBlur }
                value={ values.cots_expiration_date }
                helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
                onChange={ handleChange }
                required={ true }
              />


              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                value={ values.address }
                label="Organisation Address"
                required
                name='address'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.address && touched.address && errors.address }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={ {
                  title: 'Valid Postcode',
                  pattern: postcodePattern,
                } }
                name='postcode'
                type="text"
                value={ values.postcode }
                label="Organisation Postcode"
                required
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.postcode && touched.postcode && errors.postcode }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="tel"
                value={ values.phone }
                label="Organisation Phone Number"
                required
                name='phone'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.phone }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                label="Buyer Name"
                value={ values.buyer.name }
                required
                name='buyer.name'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.buyer && errors.buyer.name && touched.buyer && touched.buyer.name && errors.buyer.name }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="email"
                label="Buyer Email"
                value={ values.buyer.email }
                required
                name='buyer.email'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.buyer && errors.buyer.email && touched.buyer && touched.buyer.email && errors.buyer.email }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                label="Point Person Name"
                value={ values.point_person.name }
                required
                name='point_person.name'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.point_person && errors.point_person.name && touched.point_person && touched.point_person.name && errors.point_person.name }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />
              <br />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="tel"
                label="Point Person Phone"
                value={ values.point_person.phone }
                required
                name='point_person.phone'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.point_person && errors.point_person.phone && touched.point_person && touched.point_person.phone && errors.point_person.phone }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="email"
                label="Point Person Email"
                value={ values.point_person.email }
                name='point_person.email'
                onChange={ handleChange }
                onBlur={ handleBlur }
                required
                helperText={ errors.point_person && errors.point_person.email && touched.point_person && touched.point_person.email && errors.point_person.email }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={ checkingPostcode }
                color="primary">Submit</Button>
            </form>) }
        </Formik>
      </Container>
    </Card>
  );
}

OrganisationForm.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  organisation: PropTypes.object,
};

export default OrganisationForm;
