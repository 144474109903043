import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Wc from '@material-ui/icons/Wc';
import Event from '@material-ui/icons/Event';
import PersonOutline from '@material-ui/icons/PersonOutline';
import People from '@material-ui/icons/People';
import ContactPhone from '@material-ui/icons/ContactPhone';
import ContactMail from '@material-ui/icons/ContactMail';
import AssignmentIcon from '@material-ui/icons/Assignment';
import style from '../../../../Styles';
import Button from '@material-ui/core/Button';
import { axios } from '../../../../services/networkRequest';
import { confirmAlert } from 'react-confirm-alert';

const contactFontColor = '#4C4C71';
const iconColor = '#C2C3C4';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 180,
  },
}));

const ContactDetails = (props) => {
  const guardian = props.guardians[0] || {};
  const classes = useStyles();
  const dateString = new Date(props.dob).toLocaleDateString();
  return (
    <div className={ classes.root }>
      <Grid container spacing={ 1 } style={ { marginTop: '8px' } }>
        <Grid item xs={ 4 } style={ { borderRight: '1px solid #aaa' } }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><Event htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{ `${ props.first_name } D.O.B.` }</Typography>
              <Typography style={ { color: contactFontColor } }>{ dateString }</Typography>
            </Grid>
            <Grid item xs={ 3 }><Wc htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>Gender</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.gender }</Typography>
            </Grid>
            <Grid item xs={ 3 }><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>Service User Reference</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.service_user_reference }</Typography>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={ 3 }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><PersonOutline htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>Guardian name</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ (guardian.first_name || guardian.last_name) ? `${ guardian.first_name } ${ guardian.last_name }` : 'Missing name' }</Typography>
            </Grid>
            <Grid item xs={ 3 }><People htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>Guardian relationship</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.relationship || 'Missing relationship' }</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={ 3 }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><ContactPhone htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>Mobile</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.telephone || 'Missing phone number' }</Typography>
            </Grid>
            <Grid item xs={ 3 }><ContactMail htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>Email</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.email || 'Missing email' }</Typography>
            </Grid>
          </Grid>
        </Grid>
        {!props.started &&
        <Grid item xs={ 2 }>
          <Grid container spacing={ 1 }>
            <ThemeProvider theme={ style }>
              <Button materi variant="contained" color="primary"
                      onClick={ () => {

                        confirmAlert({
                          title: `Resend invite to ${ guardian.first_name } ${guardian.last_name}`,
                          message: 'Do you want to resend the SMS invite to the guardian?',
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                axios.post(`/ln/resend_invite/${guardian.id}/${props.id}`);
                              },
                            },
                            {
                              label: 'Cancel',
                              onClick: () => {
                              },
                            },
                          ],
                        });
                      } }>
                RESEND INVITE
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
        }
      </Grid>
    </div>
  );
};

export default ContactDetails;