import React from 'react';
import YoungPersonForm from './YoungPersonForm';
import { axios } from '../../../services/networkRequest';

const EditYoungPersonPage = ({ history, ...props }) => {

  const onSubmitForm = async (values) => {
    return await axios.put(`/ln/young_persons/${ props.location.state.youngPerson.id }`, values)
      .then(() => {
        history.replace('/ln/young_persons');
      });
  }

  return (
    <YoungPersonForm
      title="Edit the young person's profile"
      history={ history }
      path={ `/ln/young_persons/${ props.location.state.youngPerson.id }` }
      youngPerson={ props.location.state.youngPerson }
      onSubmitForm={ onSubmitForm }
      { ...props }
    />
  );
};

export default EditYoungPersonPage;