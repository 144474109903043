import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useUserContext } from '../../store/UserContext';
import { redirectFactory } from '../../services/helpers';
import { Games } from '../../constants';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  background: {
    width: '100vw',
    height: '100vh',
  },
  logo: {
    position: 'absolute',
    left: 32,
    width: '100px',
    height: 'auto',
  },
  gameLogo: {
    marginTop: '5vh',
    width: '15vw',
    cursor: 'pointer',
  },
}));

export default function GameChoice(props) {

  const { pickGame, user } = useUserContext();
  const classes = useStyles();

  const redirect = redirectFactory(props, pickGame);

  if(user.games.length === 1) {
    if(user.games[0] === 'cots') {
      redirect(Games.COTS);
    } else if(user.games[0] === 'ln') {
      redirect(Games.LN);
    }
  }

  return (
    <Container component="main" maxWidth="lg">
      <img src='/BfB_logo_blue.png' alt='logo' className={ classes.logo } />

      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 }><Typography color="textPrimary" align="center" variant='h5' style={ {
          fontWeight: 600,
          marginTop: '100px',
          color: '#043494',
          fontSize: '32px',
        } }>
          { 'Welcome ' + user.name + ',' }</Typography></Grid>
        <Grid item xs={ 12 }><Typography align="center" variant='h5' style={ { fontWeight: 400 } }>
          { 'Choose an intervention below to begin:' }</Typography></Grid>

        <Grid item xs={ 2 } />
        <Grid item xs={ 4 }>
          <img src={ Games.COTS.logo }
               alt={ `${ Games.COTS.name } logo` }
               className={ classes.gameLogo }
               onClick={ () => redirect(Games.COTS) } />
        </Grid>
        <Grid item xs={ 4 }>
          <img src={ Games.LN.logo }
               alt={ `${ Games.LN.name } logo` }
               className={ classes.gameLogo }
               onClick={ () => redirect(Games.LN) } />
        </Grid>
        <Grid item xs={ 2 } />

        <Grid item xs={ 2 } />
        <Grid item xs={ 4 } onClick={ () => redirect(Games.COTS) }><Typography color="textPrimary" align="center"
                                                                               variant='h5'
                                                                               style={ { cursor: 'pointer' } }>
          { 'Champions of the Shengha Pro' }</Typography>
          <Typography color="textSecondary" align="center" variant='h5'
                      style={ { fontSize: '18px', cursor: 'pointer' } }>
            { 'for mindful breathing' }</Typography></Grid>
        <Grid item xs={ 4 } onClick={ () => redirect(Games.LN) }><Typography color="textPrimary" align="center"
                                                                             variant='h5'
                                                                             style={ { cursor: 'pointer' } }>
          { 'Lumi Nova: Tales of Courage' }</Typography>
          <Typography color="textSecondary" align="center" variant='h5'
                      style={ { fontSize: '18px', cursor: 'pointer' } }>
            { 'to help reduce worries' }</Typography></Grid>
        <Grid item xs={ 2 } />
      </Grid>
    </Container>
  );
}
