import React from 'react';
import EntityForm from './EntityForm';
import { getChain } from '../../services/helpers';


function EntityCreatePage(props) {
  return <EntityForm
    { ...props }
    path='/entity'
    title={ 'Create an Entity' }
    organisation={ getChain(props, 'location', 'state', 'organisation') }
  />;
}

export default EntityCreatePage;
