import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryLine, VictoryScatter, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { barColor } from '../../Styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 230,
    width: 600,
    paddingBottom: 30
  },
  centre: {
    margin: 'auto',
  },
}));

const GBOResults = (props) => {
  const classes = useStyles();

  if (!props.responses) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const chartData = props.responses.map((item, index) => {
    // Axis label hackery from first item date to avoid keeping lookup tables
    // This comes in UK display format, but that's not how we want it any more.
    const tempParts = item.date.split('/');
    const tempString = tempParts[1] + '/' + tempParts[0] + '/' + tempParts[2];
    const date = new Date(tempString);
    const dateString = date.toDateString();
    const parts = dateString.split(' ');
    const axisLabel = parts[2] + ' ' + parts[1];
    return {
        day: index + 1,
        axisLabel,
        response: item.response
    }
  });
  
  const dayValues = chartData.map(item => item.day);
  const labels = chartData.map(item => item.axisLabel);
  let tickValues = [0, 2, 4, 6, 8, 10];
  
  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                     tickFormat={ labels }
                     label={'Date'}
                     style={ { axisLabel: { padding: 35 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     label='GBO Score'
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryLine
          tickValues={ dayValues }
          style={ { data: { stroke: barColor } } }
          data={ chartData }
          x="day"
          y="response"
        />
        <VictoryScatter
          tickValues={ dayValues }
          style={ { data: { fill: barColor } } }
          data={ chartData }
          x="day"
          y="response"
        />
      </VictoryChart>
    </div>
  );
};

export default withSize()(GBOResults);