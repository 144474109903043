import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Container, Grid, Typography } from '@material-ui/core';
import { axios } from '../../services/networkRequest';
import ExitButton from '../ExitButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GBOResults from './GBOResults';
import GuardianAnxietySurveyResults from './GuardianAnxietySurveyResults';
import Footer from '../content/Footer';
import { GBOInformation, CORSInformationCard } from './GraphInformationCards';
import CORSSurveyResults from './CORSSurveyResults';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 275,
    marginBottom: '16px',
  },
  gboContainer: {
    paddingBottom: '60px',
  },
  heading: {
    marginBottom: '12px',
  },
  italic: {
    fontStyle: 'italic',
  },
  listItem: {
    width: '100%',
  },
  question: {
    paddingRight: '25px',
  },
  underline: {
    textDecoration: 'underline',
  },
  noUnderline: {
    textDecoration: 'none',
  },
  goalSpace: {
    paddingTop: '45px',
  },
  scoreSpace: {
    paddingTop: '25px',
  },
  surveyText: {
    paddingLeft: '26px',
  },
  goalTable: {
    width: '100%',
  },
  tableCell: {
    border: '1px solid grey',
  },
  button: {
    marginTop: '30px',
    backgroundColor: '#4F9AE4',
    color: '#EEFFFF',
    marginBottom: '30px'
  },
  showHideButton: {
    display: 'inline-block',
    textAlign: 'right',
    float: 'right',
    marginBottom: '16px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#0079C6',
    },
    '&:active': {
      backgroundColor: 'white',
      borderColor: 'white',
      color: '#0079C6',
    },
  },
}));

export default function GuardianSurvey(props) {
  const young_person_id = props.match.params.id;
  const classes = useStyles();
  const [goalResults, setGoalResults] = useState(undefined);
  const [gboResponses, setGBOResponses] = useState(undefined);
  const [corsSurveys, setCorsSurveys] = useState(undefined)
  const [showResults, setShowResults] = useState(true);
  const [name, setName] = useState(undefined);
  const [cors, setCors] = useState(undefined);
  const [surveyResultsVisible, setSurveyResultsVisible] = useState(false);

  useEffect(() => {
    axios.get(`/ln/young_persons/anxiety_results/${ young_person_id }`).then(({ data }) => {
      setGoalResults(data.goals);
      setGBOResponses(data.gbos);
      setCorsSurveys(data.cors_surveys)
      setCors(data.cors);
      setName(`${ data.first_name } ${ data.last_name }`);
    });
  }, [young_person_id]);

  if (!(goalResults && gboResponses)) {
    return null;
  }

  const toggleSurveyResultsVisible = () => setSurveyResultsVisible(!surveyResultsVisible);

  const SurveyResults = () => {
    return (
      <ol>
        { goalResults.map((goal, j) => (
          <Grid container direction="column">
            <Grid container direction='column' wrap="nowrap" key={ j }>
              <li>
                <Grid container direction="column" wrap="nowrap">
                  <Typography align='left' color='textSecondary' className={ classes.goalSpace }
                              id={ goal.url_name }>
                    { goal.goal_name } - { goal.date }
                  </Typography>
                  <ol>
                    { goal.surveys.map(({ question, answer }, index) => (
                      <Grid container direction="row" key={ index }>
                        <Grid item xs={ 6 }>
                          <li>
                            <Typography align='left' color='textPrimary'
                                        className={ classes.question }>{ question }</Typography>
                          </li>
                        </Grid>
                        <Grid item xs={ 6 }>
                          <Grid container direction='row'>
                            <Grid item xs>
                              <Typography
                                color={ answer === 'Never' ? 'textPrimary' : 'textSecondary' }
                                className={ answer === 'Never' ? classes.underline : classes.noUnderline }>Never</Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography
                                color={ answer === 'Sometimes' ? 'textPrimary' : 'textSecondary' }
                                className={ answer === 'Sometimes' ? classes.underline : classes.noUnderline }>Sometimes</Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography
                                color={ answer === 'Often' ? 'textPrimary' : 'textSecondary' }
                                className={ answer === 'Often' ? classes.underline : classes.noUnderline }>Often</Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography
                                color={ answer === 'Always' ? 'textPrimary' : 'textSecondary' }
                                className={ answer === 'Always' ? classes.underline : classes.noUnderline }>Always</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )) }
                  </ol>
                </Grid>
              </li>
              <Typography align='right' color='textSecondary'>FINAL SCORE { goal.final_score }</Typography>
            </Grid>
          </Grid>
        )) }
      </ol>
    );
  };

  return (
    <Container component="main" maxWidth="md">
      <Card className={ classes.card }>
        <CardContent>
          <Grid container direction='column' alignItems='flex-start' justify='center'
                className={ classes.gboContainer }>

            <Grid container direction="row" justify="space-between" alignItems="flex-start"
                  className={ classes.bottomBorder }>
              <Grid item xs={ 11 } className={ classes.heading }>
                <Typography variant="h5" align="left">
                  { name }'s Goal Based Outcomes
                </Typography>
              </Grid>
              <ExitButton history={ props.history } />
            </Grid>

            <Grid item xs={ 12 } className={ classes.heading }>
              <Typography variant="span" align="left" color='textPrimary'>
                How close do you feel to reaching your goal today?
              </Typography>
            </Grid>
            <Grid item xs={ 12 } className={ classes.question }>
              <Typography align="left" color='textPrimary'>
                0 - 1 - 2 - 3 - 4 - 5 - 6 - 7 - 8 - 9 - 10
              </Typography>
            </Grid>
            <Grid item xs={ 12 } className={ classes.italic }>
              <Typography align="left" color='textSecondary'>
                0 means not close
              </Typography>
              <Typography align="left" color='textSecondary'>
                10 means very close
              </Typography>
            </Grid>
            <Grid item xs={ 12 }><GBOResults responses={gboResponses} /></Grid>
          </Grid>

          <GBOInformation/>
          
          {!cors &&<Grid container direction='column' alignItems='flex-start' justify='center'>
            <Grid item xs={ 12 } className={ classes.heading }>
              <Typography variant="h5" align="left">
                { name }'s Guardian Anxiety Survey Results
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography variant='h6' align='left' color='textSecondary'>Completed Surveys:</Typography>
            </Grid>
            <Grid item xs={ 12 }><GuardianAnxietySurveyResults results={goalResults} /></Grid>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={ classes.tableCell }><strong>#</strong></TableCell>
                  <TableCell align="left" className={ classes.tableCell }><strong>Survey Name</strong></TableCell>
                  <TableCell align="left" className={ classes.tableCell }><strong>Date</strong></TableCell>
                  <TableCell align="left" className={ classes.tableCell }><strong>Score</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { goalResults.map((goal, i) => {
                  const date = new Date(goal.date);
                  const dateString = date.toDateString();
                  const parts = dateString.split(' ');
                  const goalDate = [parts[2], parts[1], parts[3]].join(' ');
                  return (
                    <TableRow key={ i }>
                      <TableCell component="th" scope="row" className={ classes.tableCell }>
                        { i + 1 }
                      </TableCell>
                      <TableCell align="left" className={ classes.tableCell }>{ goal.goal_name }</TableCell>
                      <TableCell align="left" className={ classes.tableCell }>{ goalDate }</TableCell>
                      <TableCell align="left" className={ classes.tableCell }>{ goal.final_score }</TableCell>
                    </TableRow>
                  );
                }) }
              </TableBody>
            </Table>


            <Button variant="contained" color="#4D9BE9" disableElevation className={ classes.button }
                    onClick={ () => toggleSurveyResultsVisible() }>{ surveyResultsVisible ? 'HIDE' : 'VIEW' } COMPLETED
              SURVEYS</Button>

            { surveyResultsVisible ? <SurveyResults /> : null }

          </Grid>}


          {
          cors && 
          <Grid container direction='column' alignItems='flex-start' justify='center'>
          <Grid item xs={12} className={classes.heading}>
            <Typography variant="h6" align="left">
              {name}'s Child Outcome Rating Scale (CORS) Results
            </Typography>
            <Typography align="left" style={{color: 'grey'}}>
              Completed Surveys:
            </Typography>
          </Grid>
          <Grid item xs={12}><CORSSurveyResults results={corsSurveys} /></Grid>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}><strong>#</strong></TableCell>
                <TableCell align="left" className={classes.tableCell}><strong>Child Outcome Rating Scale (CORS) - Completed On</strong></TableCell>
                <TableCell align="left" className={classes.tableCell}><strong>Score</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {corsSurveys.map((survey, i) => {
                const date = new Date(survey.survey_date);
                const dateString = date.toDateString();
                const parts = dateString.split(' ');
                const goalDate = (i === 0 ) ? 'Baseline - ' + [parts[2], parts[1], parts[3]].join(' ') : [parts[2], parts[1], parts[3]].join(' ');
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row" className={classes.tableCell}>
                      {i + 1}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>{goalDate}</TableCell>
                    <TableCell align="left" className={classes.tableCell}>{survey.total}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Button variant="contained" color="primary"
                          className={ classes.showHideButton }
                          onClick={ () => {
                            setShowResults(!showResults)
                          } }>
                    {showResults ? 'HIDE' : 'SHOW'} COMPLETED SURVEYS
          </Button>

          {showResults && 
            corsSurveys.map((survey, i) => {
              const date = new Date(survey.survey_date);
              const dateString = date.toDateString();
              const goalDate = (i === 0 ) 
              ? 'CORS - Baseline completed on ' + dateString
              : 'CORS - completed on ' + dateString
              return (
                <div>
                  <Typography align="left" style={{ fontWeight: 600, width: '500px' }}> {i + 1}. {goalDate} </Typography>
                  <Table>
                    <TableBody>
                    {
                      Object.entries(survey.answers).map(([key, value]) =>
                        <TableRow>
                          <TableCell style={{border: 'none', padding: 0, paddingLeft: '25px'}} align="left" className={classes.tableCell}>{key.trim()}</TableCell>
                          <TableCell style={{border: 'none', padding: 0}} align="left" className={classes.tableCell}>{value.trim()}</TableCell>
                        </TableRow>
                        )
                    }
                    <Typography align="left">TOTAL SCORE: {survey.total}</Typography>
                    </TableBody>
                  </Table>
                </div>    
              );
            })
          }

          <CORSInformationCard/>
        </Grid>
          }

        </CardContent>
      </Card>
      <Footer />
    </Container>
  );
}
