import React, { useEffect, useState } from 'react';
import { Button, Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import { axios } from '../../services/networkRequest';
import { genericAlert, getChain } from '../../services/helpers';
import { activeColor, listBackgroundColor } from '../../Styles';

import 'react-confirm-alert/src/react-confirm-alert.css';
import MaterialTable from 'material-table';
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon';
import { CloudDownload } from '@material-ui/icons';
import { Games } from '../../constants';


function AggregatedData(props) {
  const [files, setFiles] = useState();

  const url = props.game === Games.LN.shortName ? '/historic_data' : '/cots/historic_data';
  const postUrl = props.game === Games.LN.shortName ? '/aggregate_data' : '/cots/aggregate_data';

  function downloadXLSX(filename) {
    axios.getFile(`${url}/${ filename }`, filename);
  }

  useEffect(() => {
    axios.get(url)
      .then(({ data }) => {
        setFiles(getChain(data, 'files'));
      });
  }, [url]);

  return (
    <Container component="main" maxWidth="lg">
      <Button mb={ 10 } align="right" variant="contained" color="primary"
              onClick={ () => genericAlert('Start processing',
                'We\'ll start collecting the data when you click "Yes", we\'ll send you an email when it is ready for you',
                () => {
                  axios.post(postUrl);
                })
              }>Get Updated Data</Button>
      <br />
      <br />
      <ThemeProvider theme={ tableThemer(files) }>
        <MaterialTable
          isLoading={ !files }
          columns={ [
            {
              title: 'Filename',
              field: 'file',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
            },
            {
              title: 'Last Modified',
              field: 'last_modified',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: 'File Size',
              field: 'size',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          data={ files }
          options={ {
            actionsColumnIndex: 3,
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }

          actions={ [
            {
              icon: () => <CloudDownload />,
              onClick: (event, rowData) => downloadXLSX(rowData.file),
            },
          ] }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
      </ThemeProvider>
    </Container>
  );
}

export default AggregatedData;
