import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Card, CardContent, Container, Grid, Typography } from '@material-ui/core';
import { axios, CancelToken } from '../../services/networkRequest';
import { getChain, identity } from '../../services/helpers';
import { barColor, lightBlueCardColor, numberTextColor } from '../../Styles';
import { format } from 'date-fns';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PPDashboardReportLN from './PPDashboardReportLN';
import Footer from '../content/Footer';

const useStyles = makeStyles({
  noWrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

function PPDashboardLN() {
  const isPrint = useMediaQuery('print');
  const classes = useStyles();
  const [dashData, setDashData] = useState();

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    axios.get('/ln/ppdashboard', {
      cancelToken,
    }).then(({ data }) => {
      setDashData(data);
    }).catch(identity);

    return () => {
      cancel();
    };
  }, []);

  const expiration_date = getChain(dashData, 'expiration_date');
  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={ 3 }>
        <Grid item xs={ 8 }><Typography color="textPrimary" align="left" variant='h5'
                                        style={ { fontWeight: 600, marginLeft: '10px' } }>{isPrint ? 'Lumi Nova Dashboard Report' : 'Organisation Dashboard'}</Typography></Grid>
        <Grid item xs={ 4 }>
          <Typography color="textPrimary" align="right"
                      style={ { fontSize: '12px' } }>{ `Licenses provided by ${ getChain(dashData, 'organisation_name') || '' }` }</Typography>
          { expiration_date && <Typography color="textSecondary" align="right"
                                           style={ { fontSize: '12px' } }>
            { `Active until ${ format(new Date(expiration_date), 'EEEE, do MMMM yyyy') }` }</Typography> }
        </Grid>
        <Grid item xs={ 4 }>
          <Card style={ { backgroundColor: lightBlueCardColor } }>
            <CardContent>
              <Grid container>
                <Grid item xs={ 12 }><Typography color="textPrimary" align="left"
                                                 classes={ { root: classes.noWrapText } }>Total licenses allocated
                  to</Typography></Grid>
                <Grid item xs={ 12 }><Typography style={ { color: numberTextColor } }
                                                 align="left"
                                                 classes={ { root: classes.noWrapText } }>{ dashData ? dashData.organisation_name : '' }</Typography></Grid>
                <Grid item xs={ 12 }><Typography style={ { color: numberTextColor, fontSize: '36px', fontWeight: 800 } }
                                                 align="left">{ dashData ? dashData.ln_assigned_licenses : 0 }</Typography></Grid>
                <Grid item xs={ 12 }><Typography style={{ color: 'black', fontSize: "12px" }}
                                                    align="left" classes={{ root: classes.noWrapText }}>{"Total licenses used: " + (dashData ? dashData.total_used : 0)}</Typography></Grid>
                <Grid item xs={ 12 }><div style={{ width: "100%", height: "20px", border: `1px solid ${barColor}` }}><div style={{ width: dashData ? `${(dashData.total_used / dashData.ln_assigned_licenses) * 100}%` : "0%", height: "20px", backgroundColor: barColor }}/></div></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Card style={{ backgroundColor: lightBlueCardColor }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={ 12 }><Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>Total Invites Sent</Typography></Grid>
                    <Grid item xs={ 12 }><Typography style={{ color: numberTextColor, fontSize: "60px", fontWeight: 800, paddingBottom: "28px" }}>{dashData ? dashData.invited : 0}</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ backgroundColor: lightBlueCardColor }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={ 12 }><Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>Total Started Playing</Typography></Grid>
                    <Grid item xs={ 12 }><Typography style={{ color: numberTextColor, fontSize: "60px", fontWeight: 800, paddingBottom: "28px" }}>{dashData ? dashData.playing : 0}</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ backgroundColor: lightBlueCardColor }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={ 12 }><Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>Total Deactivated</Typography></Grid>
                    <Grid item xs={ 12 }><Typography style={{ color: numberTextColor, fontSize: "60px", fontWeight: 800, paddingBottom: "28px" }}>{dashData ? dashData.deactivated : 0}</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ width: "100%", height: "4px", backgroundColor: numberTextColor, marginTop: "10px" }} />
      <PPDashboardReportLN />
      <Footer />
    </Container>
  );
}

export default PPDashboardLN;
