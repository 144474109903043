import React from 'react';
import DistributorForm from './DistributorForm';


const DistributorEditPage = props =>
  <DistributorForm
    { ...props }
    path={ `/distributor/${ props.location.state.distributor.id }` }
    distributor={ props.location.state.distributor }
    title={ `Edit ${ props.location.state.distributor.name }` }
  />;

export default DistributorEditPage;
