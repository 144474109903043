import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { greyBorderColor } from '../../Styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import { months } from '../../constants';
import MenuItem from '@material-ui/core/MenuItem';
import MonthlyUsage from './MonthlyUsage';
import DailyUsage from './DailyUsage';
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { axios } from '../../services/networkRequest';
import { getChain, subtractMonth } from '../../services/helpers';


function UsageGraph(props) {
  const { playerName, classes, userData, setUserData, chartUrl } = props;

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  let startDate = new Date(userData.first_play);
  if (startDate.getDate() >= currentDate.getDate()) {
    startDate.setDate(currentDate.getDate() - 1);
  }
  const usageIntervals = [];
  let usageIndex = 0;
  for(let date = currentDate; date >= startDate; subtractMonth(date)) {
    const itemYear = date.getFullYear();
    const itemMonth = date.getMonth() + 1;
    if (itemYear === year - 1 && itemMonth === 12 && month === 1) {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
      usageIntervals[usageIndex] = { year: itemYear };
      usageIndex++;
    } else if (itemYear < year) {
      if (usageIndex > 0 && usageIntervals[usageIndex-1].year !== itemYear) {
        usageIntervals[usageIndex] = { year: itemYear };
        usageIndex++;
      }
    } else {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
    }
  }
  const [timePeriod, setTimePeriod] = useState(JSON.stringify({ month, year }));
  const usageIsMonthly = !JSON.parse(timePeriod).month;
  const usage = getChain(userData, 'usage_data', timePeriod);


  useEffect(() => {
    let url = chartUrl;
    if (timePeriod) {
      const timePeriodObj = JSON.parse(timePeriod);
      if (timePeriodObj.month) {
        url += `?month=${ timePeriodObj.month }`;
        if (timePeriodObj.year) {
          url += `&year=${ timePeriodObj.year }`;
        }
      } else if (timePeriodObj.year) {
        url += `?year=${ timePeriodObj.year }`;
      }
    }

    if (!usage) {
      axios.get(url)
        .then(({ data }) => {
          userData['usage_data'] = {
            ...userData['usage_data'],
            [timePeriod]: data,
          };
          setUserData(userData);
        });
    }
  }, [userData, setUserData, timePeriod, usage, chartUrl]);

  return (
    <Grid item xs={ 12 }>
      <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
        <CardContent>
          <Grid container>
            <Grid item xs={ 9 }>
              <Typography align='left'
                color="textPrimary">{ `${ playerName }'s ${ usageIsMonthly ? 'monthly' : 'daily' } game usage` }</Typography>
            </Grid>
            <Grid item xs={ 1 }>
              <Typography align='right' color="textPrimary" style={ { marginRight: '5px' } }>Filter by:</Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Select
                value={ timePeriod }
                onChange={ event => {
                  setTimePeriod(event.target.value);
                } }
                variant='outlined'
                classes={ { root: classes.select } }
              >
                { usageIntervals.map((item, index) => {
                  let itemString;
                  if (index === 0) {
                    itemString = `Current Month (${ months[item.month - 1] })`;
                  } else if (index === 1) {
                    itemString = `Previous Month (${ months[item.month - 1] })`;
                  } else if (item.month) {
                    itemString = months[item.month - 1];
                  } else {
                    itemString = item.year.toString();
                  }
                  const value = JSON.stringify({ month: item.month, year: item.year });
                  return (<MenuItem key={ value } value={ value }>{ itemString }</MenuItem>);
                }) }
              </Select>
            </Grid>
          </Grid>
          { usageIsMonthly ? <MonthlyUsage usage={ usage } /> : <DailyUsage usage={ usage } /> }
        </CardContent>
      </Card>
    </Grid>
  );
}

UsageGraph.propTypes = {
  playerName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  chartUrl: PropTypes.string.isRequired,
};

export default UsageGraph;