import React from 'react';
import { Button, Card, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteAlert } from '../../services/helpers';

class DistributorDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distributor: this.props.location.state.distributor,
    };
    this.editDistributor = this.editDistributor.bind(this);
    this.deleteDistributor = this.deleteDistributor.bind(this);
  }

  deleteDistributor() {
    const { name, id } = this.state.distributor;
    deleteAlert(name, `/distributor/${ id }`, () => {
      this.props.history.goBack();
    });
  }

  editDistributor() {
    this.props.history.push(`/distributor/${ this.state.distributor.id }/edit`, { distributor: this.state.distributor });
  }

  render() {
    const { distributor } = this.state;
    return (
      <div>
        <Button onClick={ () => this.editDistributor() }>
          <EditIcon />
        </Button>
        <Button onClick={ () => this.deleteDistributor() }>
          <DeleteIcon />
        </Button>
        <Card>
          <Typography><strong>Distributor</strong></Typography>
          <Typography><strong>Name:</strong> { distributor.name }</Typography>
          <Typography><strong>Address:</strong> { distributor.address }</Typography>
          <Typography><strong>Email:</strong> { distributor.email }</Typography>
          <Typography><strong>Phone:</strong> { distributor.phone }</Typography>
        </Card>
      </div>
    );
  }
}

export default DistributorDetailsPage;
