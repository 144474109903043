import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    informationCard: {
      backgroundColor: '#EBEBEB',
      paddingLeft:'10px',
      paddingRight:'10px',
      paddingBottom:'10px',
      marginBottom:'16px',
      marginTop: '8px'
    },
    informationCardText: {
      fontStyle: 'italic',
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.8rem',
    }
  }));

export function GBOInformation() {
  const classes = useStyles();

    return (
        <Grid className={ classes.informationCard } container spacing="2">
          <Grid item>
            <Typography className={ classes.informationCardText }>Information about GBOs:</Typography>
          </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                GBOs are a way of evaluating progress towards a goal in the digital therapeutic game “Lumi Nova: Tales of Courage” with children and young people. GBOs compare how far a child or young person feels they have moved towards reaching their chosen goal at the beginning of the game and every week going forward.
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                Progress toward a chosen goals is rated weekly by the child on a scale from 0 (not close to reaching my goal/no progress) to 10 (very close to reaching my goal/goal has been reached). The outcome is the amount of movement along the scale from the start date of a goal to the end of a goal/use of the intervention.
              </Typography>
            </Grid>
            
            <Grid item>
              <Typography className={ classes.informationCardText } display="inline">
                Learn more: 
              </Typography>
              <a href="https://www.corc.uk.net/outcome-experience-measures/goal-based-outcomes/" target='_blank' 
              className={ classes.informationCardText } rel="noopener noreferrer">
                https://www.corc.uk.net/outcome-experience-measures/goal-based-outcomes/ 
              </a>
            </Grid>
          </Grid>
    )
}

export function CORSInformationCard() {
    const classes = useStyles();

    return(
        <Grid className={ classes.informationCard } container spacing="2">
          <Grid item>
            <Typography  className={ classes.informationCardText }>
              Information about CORS:
            </Typography>
          </Grid>

            <Grid item>
                <Typography className={ classes.informationCardText }>
                    The Child Outcome Rating Scale (CORS) is a four-item scale that assesses a parent or guardian's perceptions of their child’s problems during therapeutic treatment (Lumi Nova):
                </Typography>
            </Grid>

            <Grid item>
                <ol className={ classes.informationCardText }>
                    <li className={ classes.informationCardText }>Me (How am I doing?)</li>
                    <li className={ classes.informationCardText }>Family (How are things in my family?)</li>
                    <li className={ classes.informationCardText }>School (How am I doing at school?)</li>
                    <li className={ classes.informationCardText }>Everything (How is everything going?)</li>
                </ol>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                The CORS measure is sent to the guardian via SMS on a weekly basis from the start of the intervention until the end. The total score for each measure is represented on the graph above.
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                Individual responses for the CORS are calculated based on the point selected on each item's line. Each line indicates a scale from 0-10, inclusive of decimals, with 0.0 as the furthest left point and 10.0 as the furthest right point. Points selected on the left indicate a lower score, whereas a point selected on the right indicates a higher score. 
              </Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <Typography className={ classes.informationCardText }>Cut off scores:</Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                The CORS cut off score between the clinical population and the non-clinical population for carer reporting on child is 28.
              </Typography>
            </Grid>
              
            <Grid item>
              <Typography className={ classes.informationCardText } display="inline">
                Learn more: 
              </Typography>
              <a href="https://www.corc.uk.net/outcome-experience-measures/outcome-rating-scale-ors-child-outcome-rating-scale-cors/ " target='_blank' 
               className={ classes.informationCardText } rel="noopener noreferrer">
                https://www.corc.uk.net/outcome-experience-measures/outcome-rating-scale-ors-child-outcome-rating-scale-cors/ 
              </a>
            </Grid>
          </Grid>
    )
}