import React from 'react';
import { Container, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  background: {
    width: '100vw',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: 10,
    left: 0,
    width: '100px',
    height: 'auto',
  },
  textContainer: {
    marginTop: 160
  },
  headerText: {
    fontWeight: 'bold'
  },
  text: {
    marginTop: 25,
    marginBottom: 35
  },
  owlImage: {
    width: '550px',
    height: 'auto'
  }
}));

export default function MaintenancePage() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xl" className={ classes.background }>
      <Grid container direction="column">
        <Grid item>
          <img src='/BfB_logo_blue.png' alt='logo' className={ classes.logo } />
        </Grid>
        <Grid container className={ classes.textContainer} direction='column'>
          <Grid item>
            <Typography variant="h4" className={ classes.headerText }>
              Tweet Twoo! Sorry for the inconvenience, we&apos;ll be back online soon!
            </Typography>
          </Grid>
          <Grid item className={ classes.text }>
            <Typography>
              The hub is under going some scheduled maintenance to ensure you get the best experience!
              <br />
              The maintenance will only take a few hours at the most. Please check again later.
            </Typography>
          </Grid>
          <img style={ { margin: 'auto' } } src="/maintenance_owl.png" alt="Maintenance" className={ classes.owlImage } />
        </Grid>
      </Grid>
    </Container>
  );
}
