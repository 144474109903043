import React from 'react';
import EntityForm from './EntityForm';
import { getChain } from '../../services/helpers';


const EntityEditPage = props =>
  <EntityForm
    { ...props }
    path={ `/entity/${ getChain(props.location.state.entity, 'id') }` }
    entity={ props.location.state.entity }
    title={ `Edit ${ getChain(props.location.state.entity, 'name') }` }
    organisation={ props.location.state.organisation }
  />;

export default EntityEditPage;
