import React from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { FormControl, FormGroup } from '@material-ui/core';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';

class DistributorsInvite extends React.Component {
  render() {
    const { org_id, ent_id } = this.props.match.params;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography component="h1" variant="h5">
          Invite Distributor
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ { email: '', first_name: '', last_name: '', organisation_id: org_id, entity_id: ent_id } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            axios.post('/invite/distributor', values).then(() => {
              this.props.history.goBack();
            })
              .catch(catchAxios(setFieldError))
              .finally(() => setSubmitting(false));
          } }
        >
          { ({
               errors,
               values,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
             }) => (
            <form onSubmit={ handleSubmit }>
              <FormControl>
                <FormGroup>
                  <Error message={ errors['network'] } />
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    type="email"
                    name="email"
                    id="email"
                    value={ values.email }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    label="Distributor Email"
                    required
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="first_name"
                    name="first_name"
                    id="first_name"
                    value={ values.first_name }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    label="Distributor First Name"
                    required
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="last_name"
                    name="last_name"
                    id="last_name"
                    value={ values.last_name }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    label="Distributor Last Name"
                    required
                  />
                </FormGroup>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={ isSubmitting }
                  type='submit'>
                  Submit
                </Button>
              </FormControl>
            </form>
          ) }
        </Formik>
      </Container>
    );
  }
}

export default DistributorsInvite;
