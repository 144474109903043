import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Container, Grid, Typography } from '@material-ui/core';
import ExitButton from '../../ExitButton';
import Footer from '../../content/Footer';

const useStyles = makeStyles(() => ({
  bottomBorder: {
    borderBottom: '1px solid #ccc',
  },
  topBorder: {
    borderTop: '1px solid #ccc',
    marginTop: '75px',
    paddingTop: '20px',
  },
  card: {
    minWidth: 275,
  },
  text: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: '75px',
  },
  textSpace: {
    paddingTop: '20px',
  },
}));

export default function CotsConfirmationScreen(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="lg">
      <Card className={ classes.card }>
        <CardContent>
          <Grid container direction="row" justify="space-between" alignItems="center"
                className={ classes.bottomBorder }>
            <Grid item xs={ 3 }>
              <Typography component="h6" variant="body1" align="left">Create a young person's profile</Typography>
            </Grid>
            <ExitButton history={ props.history } />
          </Grid>
          <Grid container className={ classes.text } direction='column' alignItems='center' justify='center'>
            <Grid item xs={ 6 }>
              <Typography component="h1" align="center" variant="body1">
                PROFILE CREATED
              </Typography>
            </Grid>
            <Grid item xs={ 6 }>
              <Typography component="h1" align="center" variant="body1" className={ classes.textSpace }>
                An automatic SMS will now go to the provided phone number with a game download link
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={ 2 } className={ classes.topBorder } justify='center'>
            <Grid item xs={ 2 }>
              <Button fullWidth variant="contained" color="primary" onClick={ () => {
                props.history.replace('/cots/young_persons');
              } }>
                OK
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Footer />
    </Container>
  );
}
