import React from 'react';
import OrganisationForm from './OrganisationForm';


function OrganisationsCreatePage(props) {
  return <OrganisationForm
    { ...props }
    path='/organisation'
    title={ 'Create an Organisation' }
  />;
}

export default OrganisationsCreatePage;
