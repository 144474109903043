import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Radio } from '@material-ui/core';
import { Formik } from 'formik';
import { axios, catchAxios } from '../../../services/networkRequest';
import {
  formatDate,
  genericAlert,
  getChain,
  disabilityParser,
  disabilityUnparser,
  genderParser,
  genderUnparser,
} from '../../../services/helpers';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import { helperTextColor, checkPostcodeValidationRequired, countryList } from '../../../constants';
import { addYears } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import RequiredInfoText from '../../RequiredInfoText';
import ExitButton from '../../ExitButton';
import Error from '../../Error';

const phoneUtil = PhoneNumberUtil.getInstance();

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  countryDropdown: {
    width: '100%',
    marginTop: '16px',
    height: '70%',
    textAlign: 'center',
    borderRadius: '5px',
    background: 'white',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  detailSection: {
    marginTop: '45px',
    fontWeight: 'normal',
  },
  bottomBorder: {
    borderBottom: '1px solid #ccc',
  },
  topBorder: {
    borderTop: '1px solid #ccc',
    marginTop: '75px',
    paddingTop: '20px',
  },
  disabilitySpace: {
    marginTop: '10px',
  },
  card: {
    minWidth: 275,
  },
  helperText: {
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
  },
}));


export default function YoungPersonForm({ youngPerson, path, title, history }) {
  const method = youngPerson ? 'put' : 'post';
  const classes = useStyles();

  // Convert the date format to the one expected by the form.
  let dob = getChain(youngPerson, 'dob') || '';
  if (dob) {
    dob = formatDate(new Date(dob));
  }

  const maxDateString = formatDate(addYears(new Date(), -6));
  const id = getChain(youngPerson, 'id');

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const confirmation = callback => {
    genericAlert('Cancel Young Person Form', 'All unsaved data will be lost', callback);
  };

  const country = getChain(youngPerson, 'country') || 'United Kingdom';
  let [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(country); 

  return (
    <Container component="main" maxWidth="lg">
      <Card className={ classes.card }>
        <CardContent>
          <Formik
            initialValues={ {
              child: {
                first_name: getChain(youngPerson, 'first_name') || '',
                last_name: getChain(youngPerson, 'last_name') || '',
                dob: dob,
                ethnicity: getChain(youngPerson, 'ethnicity') || '',
                disability: disabilityParser(getChain(youngPerson, 'has_disability')),
                gender: genderParser(getChain(youngPerson, 'gender')),
                post_code: getChain(youngPerson, 'post_code'),
                telephone: getChain(youngPerson, 'telephone'),
                service_user_reference: getChain(youngPerson, 'service_user_reference'),
                country: country
              },
            } }

            validate={ values => {
              const errors = {};
              if (!values.child.first_name) {
                errors.childFirstName = 'Please specify the first name';
              }
              if (!values.child.last_name) {
                errors.childLastName = 'Please specify the last name';
              }
              if (!values.child.dob) {
                errors.dob = 'Please specify date of birth';
              }
              if (!values.child.telephone) {
                errors.number = 'Please specify number';
              } else {
                try {
                  const number = phoneUtil.parseAndKeepRawInput(values.child.telephone, 'GB');
                  if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                    errors.number = 'Please enter a valid number (with international dialling code if not +44)';
                  }
                } catch (err) {
                  errors.number = 'Please enter a valid number (with international dialling code if not +44)';
                }
              }
              if (values.child.post_code) {
                values.child.post_code = values.child.post_code.toUpperCase();
              }

              [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(values.child.country);
            
              return errors;
            } }
            onSubmit={ (values, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              // Reverse date format for the backend
              const reformattedDoB = formatDate(values.child.dob);
              // Convert phone number into international format
              const number = phoneUtil.parseAndKeepRawInput(values.child.telephone, 'GB');
              const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
              const updatedValues = update(values, {
                child: {
                  dob: { $set: reformattedDoB },
                  disability: { $set: disabilityUnparser(values.child.disability) },
                  gender: { $set: genderUnparser(values.child.gender) },
                  telephone: { $set: internationalNumber },
                },
              });
              if (youngPerson && !id) {
                // Young Person with no ID means that it is a demo user
                history.replace('/cots/young_persons');
              } else {
                axios[method](path, updatedValues)
                  .then(() => {
                    if (youngPerson) {
                      history.replace('/cots/young_persons');
                    } else {
                      history.replace('/cots/young_person/confirm');
                    }
                  })
                  .catch(err => {
                    if (getChain(err, 'response', 'status') === 402) {
                      const { title, body: message } = getChain(err, 'response', 'data');
                      genericAlert({
                        title,
                        message,
                        negativeTitle: 'Back',
                      });
                    } else {
                      catchAxios(setFieldError)(err);
                    }
                    setSubmitting(false);
                  });
              }
            } }
          >
            { ({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
                 /* and other goodies */
               }) => {
              return (
                <form onSubmit={ handleSubmit } autoComplete="off">
                  <Grid container direction="row" justify="space-between" alignItems="center"
                        className={ classes.bottomBorder }>
                    <Grid item xs={ 11 }>
                      <Typography component="h6" variant="body1" align="left">{ title }</Typography>
                    </Grid>
                    <ExitButton history={ history } confirmation={ confirmation } />
                  </Grid>
                  <RequiredInfoText align='left' />
                  <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
                    YOUNG PERSON DETAILS
                  </Typography>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                      <TextField
                        className={ classes.textField }
                        label="Young Person First Name"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="child.first_name"
                        autoFocus
                        onBlur={ handleBlur }
                        value={ values.child.first_name }
                        helperText={ errors.childFirstName && touched.child && touched.child.first_name && errors.childFirstName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>
                    <Grid item xs={ 6 }>
                      <TextField
                        className={ classes.textField }
                        label="Young Person Last Name"
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.last_name"
                        onBlur={ handleBlur }
                        value={ values.child.last_name }
                        helperText={ errors.childLastName && touched.child && touched.child.last_name && errors.childLastName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>

                    <Grid item xs={ 6 }>
                      <TextField
                        inputProps={ {
                          max: maxDateString,
                        } }
                        className={ classes.textField }
                        label="Young Person Date of Birth"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={ {
                          shrink: true,
                        } }
                        type="date"
                        name="child.dob"
                        onBlur={ handleBlur }
                        value={ values.child.dob }
                        helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>
                    <Grid item xs={ 6 }>
                      <FormControl variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="gender-select-outlined-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="gender-select-outlined-label"
                          id="gender-select-outlined"
                          name="child.gender"
                          value={ values.child.gender }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={ 'Male' }>Male</MenuItem>
                          <MenuItem value={ 'Female' }>Female</MenuItem>
                          <MenuItem value={ 'n/a' }>Prefer not to say</MenuItem>
                        </Select>
                        <Typography
                          className={ classes.helperText }>{ errors.gender && touched.child && touched.child.gender && errors.gender }</Typography>
                      </FormControl>
                    </Grid>
                    <Grid item xs={ 6 }>
                      <FormControl variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="ethnicity-select-outlined-label">
                          Ethnicity
                        </InputLabel>
                        <Select
                          labelId="ethnicity-select-outlined-label"
                          id="ethnicity-select-outlined"
                          name="child.ethnicity"
                          value={ values.child.ethnicity }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={ 'white' }>White</MenuItem>
                          <MenuItem value={ 'mixed/multiple ethnic groups' }>Mixed/Multiple ethnic groups</MenuItem>
                          <MenuItem value={ 'asian/asian british' }>Asian/Asian British</MenuItem>
                          <MenuItem value={ 'black/african/caribbean/black british' }>Black/African/Caribbean/Black
                            British</MenuItem>
                          <MenuItem value={ 'chinese' }>Chinese</MenuItem>
                          <MenuItem value={ 'arab' }>Arab</MenuItem>
                          <MenuItem value={ 'other ethnic group' }>Other ethnic group</MenuItem>
                        </Select>
                        <Typography
                          className={ classes.helperText }>{ errors.ethnicity && touched.child && touched.child.ethnicity && errors.ethnicity }</Typography>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ 3 }>
                      <FormControl variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="country-select-outlined-label">
                          Country
                        </InputLabel>
                        <Select
                            labelId="country-select-outlined-label"
                            id="country-select-outlined"
                            name="child.country"
                            value={ values.child.country }
                            className={ classes.textField }
                            labelWidth={ labelWidth }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                          >
                            {
                              countryList.map(country => 
                                <MenuItem value={country}>{country}</MenuItem>
                                )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ 3 }>
                      <TextField
                        className={ classes.textField }
                        inputProps={ postcodeValidation }
                        label="Postcode"
                        required={ postcodeValidationRequired }
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.post_code"
                        onBlur={ handleBlur }
                        value={ values.child.post_code }
                        onChange={ handleChange }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 } className={ classes.disabilitySpace }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        <FormLabel component="legend">Has the young person got a disability? (Optional)</FormLabel>
                        <RadioGroup
                          aria-label="gender"
                          name="child.disability"
                          value={ values.child.disability }
                          onChange={ handleChange }>
                          <FormControlLabel value={ 'true' } control={ <Radio /> } label="Yes" />
                          <FormControlLabel value={ 'false' } control={ <Radio /> } label="No" />
                          <FormControlLabel value={ 'n/a' } control={ <Radio /> } label="Prefer not to say" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <TextField
                          className={ classes.textField }
                          label="Service User Reference"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="text"
                          name="child.service_user_reference"
                          autoFocus
                          onBlur={ handleBlur }
                          value={ values.child.service_user_reference }
                          onChange={ handleChange }
                        />
                      </Grid>
                  </Grid>
                  <Grid item xs={ 6 }>
                      <TextField
                        className={ classes.textField }
                        label="Mobile Number"
                        margin="normal"
                        variant="outlined"
                        type="tel"
                        name="child.telephone"
                        onBlur={ handleBlur }
                        value={ values.child.telephone }
                        helperText={ errors.number && touched.child && touched.child.telephone && errors.number }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>
                  <Grid container spacing={ 2 } className={ classes.topBorder }>
                    <Grid item xs={ 2 }>
                      <Button variant="contained" color="primary" type="submit" disabled={ isSubmitting }
                              style={ { float: 'left' } }>
                        SAVE
                      </Button>
                    </Grid>
                    <Grid item xs={ 10 }>
                      <Error style={ { textAlign: 'left' } } message={ errors['network'] } />
                    </Grid>
                  </Grid>
                </form>
              );
            } }
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}
