import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { emailRegex } from '../../services/helpers';
import { confirmAlert } from 'react-confirm-alert';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  background: {
    width: '100%',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    left: 0,
    width: '100px',
    height: 'auto',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();

  return (
      <Container className={ classes.background } component="main" maxWidth="s">
        <img src='/BfB_logo_blue.png' alt='logo' className={ classes.logo } />
        <CssBaseline />
        <div className={ classes.paper }>
          <Typography component="h1" variant="h5">
            Enter your email to reset your password
          </Typography>
          <RequiredInfoText />
          <Formik
            initialValues={ { email: '' } }
            validate={ values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (!emailRegex.test(values.email)) {
                errors.email = 'Invalid email address';
              }
              return errors;
            } }
            onSubmit={ ({ email }, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              axios.post(props.url, { email }).then(() => {
                confirmAlert({
                  title: 'Success',
                  message: 'We have sent a password reset link to your email address',
                  buttons: [
                    {
                      label: 'OK',
                      onClick: () => {
                        props.history.push('/');
                      },
                    },
                  ],
                });
              })
                .catch(err => {
                  catchAxios(setFieldError)(err);
                  setSubmitting(false);
                });
            } }
          >
            { ({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
               }) => (
              <form onSubmit={ handleSubmit }>
                <Error message={ errors['network'] } />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="email"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  value={ values.email }
                  autoFocus
                  helperText={ errors.email && touched.email && errors.email }
                  FormHelperTextProps={ { style: { color: helperTextColor } } }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={ classes.submit }
                  disabled={ isSubmitting }
                >
                  Submit
                </Button>
              </form>
            ) }
          </Formik>
        </div>
      </Container>
  );
}

export default ForgotPassword;
