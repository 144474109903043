import React from 'react';
import { Button, Container, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { emailRegex, genericAlert } from '../../services/helpers';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';
import ExitButton from '../ExitButton';

const styles = {
  submit: {
    display: 'block',
    margin: '16px auto',
  },
};

class AdminInvite extends React.Component {
  confirmation(callback) {
    genericAlert('Cancel Admin Invitation', 'All unsaved data will be lost', callback);
  }

  render() {
    return (
      <>
        <ExitButton style={ { float: 'right', padding: '20px' } } history={ this.props.history }
                    confirmation={ this.confirmation } />
        <Container component="main" maxWidth="lg">
          <RequiredInfoText />
          <Formik
            initialValues={ { email: '' } }
            onSubmit={ ({ email }, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              axios.post('/invite/admin', { email }).then(() => {
                genericAlert({
                  title: 'Admin Invite',
                  message: 'Invite Sent',
                  positiveTitle: 'Ok',
                  positiveCallback: () => {
                    this.props.history.push('/admins');
                  },
                });
              })
                .catch(catchAxios(setFieldError))
                .finally(() => setSubmitting(false));
            } }
            validate={ values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (!emailRegex.test(values.email)) {
                errors.email = 'Invalid email address';
              }
              return errors;
            } }
          >
            { ({
                 errors,
                 values,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
               }) => (
              <form onSubmit={ handleSubmit }>
                <Error message={ errors['network'] } />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="email"
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  value={ values.email }
                  helperText={ errors.email && touched.email && errors.email }
                  FormHelperTextProps={ { style: { color: helperTextColor } } }
                  autoFocus
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={ this.props.classes.submit }
                  disabled={ isSubmitting }
                >
                  Submit
                </Button>
              </form>
            ) }
          </Formik>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(AdminInvite);
