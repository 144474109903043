import React from 'react';
import YoungPersonForm from './YoungPersonForm';
import { getChain } from '../../../services/helpers';


const CreateYoungPersonPage = ({ history, youngPerson, ...props }) => {

  const id = getChain(youngPerson, 'id');

  const onSubmitForm = (values) => {
    if (youngPerson && !id) {
      history.replace('/ln/young_persons');
    } else {
      history.push('/ln/young_person/confirm', values);
    }

    return Promise.resolve();
  }

  return (
    <YoungPersonForm
      title="Create a young person's profile"
      history={ history }
      onSubmitForm={ onSubmitForm }
      youngPerson={ youngPerson }
      { ...props }
    />
  )
};

export default CreateYoungPersonPage;
