import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { axios } from '../../services/networkRequest';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { activeColor, listBackgroundColor } from '../../Styles';

class MonthlyReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
    };
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    axios.get('point_person/reports')
      .then(reports => {
          this.setState({ reports: reports.data })
        });
  }

  componentDidMount() {
    this.loadData();
  }

  downloadReport(report) {
    axios.getFile(`point_person/report/${ report.file_name }`, report.file_name);
  }

  render() {
    const { reports } = this.state;
    return (
      <Container component="main" maxWidth="lg">
          <Typography color="textPrimary" align="left" variant='h5'
                                        style={ { fontWeight: 600, marginLeft: '10px' } }>{'Monthly Report'}</Typography>
        <ThemeProvider theme={ tableThemer(reports) }>
          <MaterialTable
            isLoading={ !reports }
            columns={ [
              {
                title: 'Filename',
                field: 'file_name',
                cellStyle: {
                  borderLeft: `20px solid ${ activeColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                color="textSecondary">{ rowData.file_name && rowData.file_name.split('/').pop() }</Typography>
              },
              {
                title: 'Created',
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                                color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>
              },
              {
                title: 'File Size',
                field: 'file_size',
                cellStyle: {
                  borderRight: 'none',
                },
              },
            ] }
            actions={ [
              {
                icon: () => <CloudDownloadIcon />,
                onClick: (event, rowData) => this.downloadReport(rowData),
              },
            ] }
            data={ reports }
            options={ {
              actionsColumnIndex: 6,
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: true,
              draggable: false,
              sorting: true,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
      </Container>
    );
  }
}

export default MonthlyReports;
