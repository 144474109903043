import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Link, withRouter } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as PropTypes from 'prop-types';
import { redirectFactory, ROLES } from '../../services/helpers';
import { useUserContext } from '../../store/UserContext';
import { Games } from '../../constants';
import { AccountCircle } from '@material-ui/icons';
import packageJson from '../../../package.json'

// Version string - needs to be updated manually
// Format: Version <Major clinical version>.<Minor clinical / feature version>.<Patch version> (<commit sha>)
const versionString = `Version ${packageJson.version}`;
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  spacedItems: {
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: '50px',
    width: 'auto',
  },
  title: {
    flexGrow: 1,
  },
  menuItems: {
    display: 'flex',
  },
  gameToggleContainer: {
    paddingTop: '5px',
    width: '500px',
    height: '40px',
    float: 'right',
    display: 'table',
    tableLayout: 'fixed',
  },
  active: {
    fontWeight: 'bold',
    backgroundColor: '#043494',
    color: 'white',
  },
  inactive: {
    backgroundColor: '#e3ecf5',
  },
  toggle: {
    textDecoration: 'none',
    borderRadius: '15px 15px 0 0',
    display: 'table-cell',
    verticalAlign: 'middle',
    width: '50%',
    cursor: 'pointer',
  },
  gameIcon: {
    width: '40px',
    border: '1px solid white',
  },
  underlined: {
    textDecoration: 'underline'
  }
}));

function MenuAppBar(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { logout, pickGame } = useUserContext();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirect = redirectFactory(props, pickGame);
  const gameDetails = Games[props.currentGame];

  const path = props.location['pathname']

  return (props.role === ROLES.distributor && !gameDetails) ? null : (
    <div className={ classes.root }>
      { props.games && props.games.length > 1 &&
      <div className={ classes.gameToggleContainer }>
        <div onClick={ () => redirect(Games.LN) }
             className={ `${ classes.toggle } ${ props.currentGame === Games.LN.shortName ? classes.active : classes.inactive }` }>
          { Games.LN.name }
        </div>
        <div onClick={ () => redirect(Games.COTS) }
             className={ `${ classes.toggle } ${ props.currentGame === Games.COTS.shortName ? classes.active : classes.inactive }` }>
          { Games.COTS.name }
        </div>
      </div> }
      <AppBar position="static">
        <Toolbar>
          <Link to='/'>
            <img src='/BfB_logo_white.png' alt='logo' className={ classes.logo } />
          </Link>
          <Typography variant="h6" className={ classes.title }>
          </Typography>
          <div className={ classes.menuItems } color="inherit" aria-label="menu">
            { /* Admin Navbar */ }
            { props.role === ROLES.admin &&
            <Link to="/aggregated_data" 
            className={ `${classes.spacedItems} ${ path.startsWith('/aggregated_data') && classes.underlined }` }>Lumi Nova Aggregated Data</Link> }
            
            { props.role === ROLES.admin &&
            <Link to="/cots/aggregated_data" 
            className={ `${classes.spacedItems} ${ path.startsWith('/cots/aggregated_data') && classes.underlined }` }>Champions of the Shengha Aggregated Data</Link> }

            { props.role === ROLES.admin && <Link to="/" 
            className={ `${classes.spacedItems} ${ (path.startsWith('/organisation') || path === '/') && classes.underlined }` }>Organisations</Link> }
            
            { props.role === ROLES.admin && <Link to="/admins" 
            className={ `${classes.spacedItems} ${ path.startsWith('/admin') && classes.underlined }` }>Admins</Link> }

            { /* Distributor Navbar */ }
            { props.role === ROLES.distributor &&
            <Link to={ `/${ gameDetails.shortName }/dashboard` } 
            className={ `${classes.spacedItems} ${ path.startsWith(`/${gameDetails.shortName}/dashboard`) && classes.underlined }` }>Dashboard</Link> }
            { props.role === ROLES.distributor &&
            <Link to={ `/${ gameDetails.shortName }/young_persons` } 
            className={ `${classes.spacedItems} ${ (path.startsWith(`/${gameDetails.shortName}/young_persons`) || path === '/') && classes.underlined }` }>Young People</Link> }
            { props.role === ROLES.distributor &&
            <a href={ gameDetails.howItWorks } rel="noreferrer noopener" target="_blank"
               className={ classes.spacedItems }>How it works</a> }
            { props.role === ROLES.distributor &&
            <Link to={ `/${ gameDetails.shortName }/resources` } 
            className={ `${classes.spacedItems} ${ path.startsWith(`/${gameDetails.shortName}/resources`) && classes.underlined }` }>Resources</Link> }            
           { props.role === ROLES.distributor &&
            <Link to={ `/${ gameDetails.shortName }/faqs` }
            className={ `${ path.startsWith(`/${gameDetails.shortName}/faqs`) && classes.underlined }` }>FAQs</Link> }

            { /* Point Person Navbar */ }
            { props.role === ROLES.point_person && 
            <Link to ={ "/point_person/dashboard" } 
            className={ `${classes.spacedItems} ${ (path.startsWith('/point_person/dashboard') || path === '/') && classes.underlined }` }>Dashboard</Link>}
            { props.role === ROLES.point_person && 
            <Link to ={ "/point_person/reports" } className={ `${classes.spacedItems} ${path.startsWith('/point_person/reports') && classes.underlined}`  }>Monthly Reports</Link>}
          </div>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={ handleMenu }
              color="inherit"
            >
              {
                gameDetails ?
                  <img src={ gameDetails.icon } alt={ `${ gameDetails.name } game icon` }
                       className={ classes.gameIcon } /> :
                  <AccountCircle />
              }
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={ anchorEl }
              anchorOrigin={ {
                vertical: 'top',
                horizontal: 'right',
              } }
              keepMounted
              transformOrigin={ {
                vertical: 'top',
                horizontal: 'right',
              } }
              open={ open }
              onClose={ handleClose }
            >
              <Typography color='textSecondary' align='center'
                          style={ {
                            fontSize: '8px',
                            width: '100%',
                          } }>{ versionString }<br />({ process.env.REACT_APP_GIT_SHA } - { props.version })</Typography>
              <MenuItem onClick={ () => {
                logout();
                window.location.replace('/');
              } }>
                Logout { props.name }
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

MenuAppBar.propTypes = {
  role: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  name: PropTypes.string,
  games: PropTypes.array,
  currentGame: PropTypes.string,
};

export default withRouter(MenuAppBar);
