import React from 'react';
import YoungPersonForm from './YoungPersonForm';

const EditYoungPersonPage = (props) => {
  return (
    <YoungPersonForm
      title="Edit the young person's profile"
      path={ `/cots/young_persons/${ props.location.state.youngPerson.id }` }
      youngPerson={ props.location.state.youngPerson }
      { ...props }
    />
  );
};

export default EditYoungPersonPage;
