import React, { useEffect, useRef, useState } from 'react';
import { axios, catchAxios } from '../../services/networkRequest';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { genericAlert, getChain } from '../../services/helpers';
import * as PropTypes from 'prop-types';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { gameKeyMax, helperTextColor } from '../../constants';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import ExitButton from '../ExitButton';
import { Card } from '@material-ui/core';

const phoneUtil = PhoneNumberUtil.getInstance();


function updateKeys(keyTotal, setKeyCount, onChange) {
  return (e) => {
    setKeyCount(keyTotal - e.target.value);

    return onChange(e);
  }
}

function EntityForm({ entity, title, path, history, organisation, match }) {

  const organisationId = getChain(organisation, 'id') || match.params.org_id;
  const method = entity ? 'post' : 'put';
  const lnOrgKeys = getChain(organisation, 'ln_game_keys') || 0;
  const lnEntKeys = getChain(entity, 'ln_game_keys') || 0;
  const lnUsedKeys = getChain(organisation, 'used_keys') || 0;
  const thisLnKeys = lnOrgKeys - lnUsedKeys + lnEntKeys;
  const [lnAvailableKeys, setLnAvailableKeys] = useState(lnOrgKeys - lnUsedKeys);
  const initialLnAvailableKeys = useRef({ value: lnAvailableKeys });

  const cotsOrgKeys = getChain(organisation, 'cots_game_keys') || 0;
  const cotsEntKeys = getChain(entity, 'cots_game_keys') || 0;
  const cotsUsedKeys = getChain(organisation, 'cots_used_keys') || 0;
  const thisCotsKeys = cotsOrgKeys - cotsUsedKeys + cotsEntKeys;
  const [cotsAvailableKeys, setCotsAvailableKeys] = useState(cotsOrgKeys - cotsUsedKeys);
  const initialCotsAvailableKeys = useRef({ value: cotsAvailableKeys });

  useEffect(() => {
    if (!organisation) {
      axios.get(`/organisation/${ organisationId }`)
        .then(response => {
          const lnKeys = getChain(response, 'data', 'ln_game_keys') - getChain(response, 'data', 'used_keys');
          const cotsKeys = getChain(response, 'data', 'cots_game_keys') - getChain(response, 'data', 'cots_used_keys');
          if (!isNaN(lnKeys)) {
            setLnAvailableKeys(lnKeys);

            // Update reference
            initialLnAvailableKeys.current.value = lnKeys;
          }
          if (!isNaN(cotsKeys)) {
            setCotsAvailableKeys(cotsKeys);

            // Update reference
            initialCotsAvailableKeys.current.value = cotsKeys;
          }
        });
    }
  }, [organisation, organisationId, lnOrgKeys, lnEntKeys, cotsOrgKeys, cotsEntKeys]);

  return (
    <Card>
      <ExitButton style={ { float: 'right', padding: '20px' } } history={ history } confirmation={ callback => {
        genericAlert('Cancel Entity Form', 'All unsaved data will be lost', callback);
      } } />

      <Container component="main" maxWidth="xs">

        <CssBaseline />
        <Typography component="h1" variant="h5">
          { title }
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ {
            name: getChain(entity, 'name'),
            address: getChain(entity, 'address'),
            ln_game_keys: lnEntKeys || 0,
            cots_game_keys: cotsEntKeys || 0,
            organisation_id: match.params.org_id,
            point_person_name: getChain(entity, 'point_person_name'),
            point_person_email: getChain(entity, 'point_person_email'),
            point_person_phone: getChain(entity, 'point_person_phone'),
          } }
          validate={ values => {
            const errors = {};
            if (!values.name) {
              errors.name = 'Please specify the entity name';
            }
            if (!values.address) {
              errors.address = 'Please specify an address';
            }
            if (!values.point_person_name) {
              errors.point_person_name = 'Please specify point person name';
            }
            if (!values.point_person_email) {
              errors.point_person_email = 'Please specify a point person email';
            }
            if (!values.point_person_phone) {
              errors.point_person_phone = 'Please specify an point person phone';
            } else {
              try {
                const number = phoneUtil.parseAndKeepRawInput(values.point_person_phone, 'GB');
                if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                  errors.point_person_phone = 'Please enter a valid number (with international dialling code if not +44)';
                }
              } catch (err) {
                errors.point_person_phone = 'Please enter a valid number (with international dialling code if not +44)';
              }
            }

            if(lnAvailableKeys > 0 && cotsAvailableKeys > 0) {
              if(!values.ln_game_keys && !values.cots_game_keys) {
                errors.ln_game_keys = errors.cots_game_keys = 'Please specify how many game keys are allocated to the entity for at least one intervention'
              } else if (values.ln_game_keys < 0 ) {
                errors.ln_game_keys = 'You must specify a positive number of game keys';
              } else if(values.cots_game_keys < 0) {
                errors.cots_game_keys = 'You must specify a positive number of game keys';
              } else if (values.ln_game_keys > gameKeyMax) {
                values.ln_game_keys = gameKeyMax;
              } else if (values.cots_game_keys > gameKeyMax) {
                values.cots_game_keys = gameKeyMax;
              }
            } else if(lnAvailableKeys > 0) {
              if (!values.ln_game_keys) {
                errors.ln_game_keys = 'Please specify how many game keys are allocated to the entity';
              } else if (values.ln_game_keys < 0) {
                errors.ln_game_keys = 'You must specify a positive number of game keys';
              } else if (values.ln_game_keys > gameKeyMax) {
                values.ln_game_keys = gameKeyMax;
              }
            } else if(cotsAvailableKeys > 0) {
              if (!values.cots_game_keys) {
                errors.cots_game_keys = 'Please specify how many game keys are allocated to the entity';
              } else if (values.cots_game_keys < 0) {
                errors.cots_game_keys = 'You must specify a positive number of game keys';
              } else if (values.cots_game_keys > gameKeyMax) {
                values.cots_game_keys = gameKeyMax;
              }
            }

            return errors;
          } }
          onSubmit={ (entity, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            // Convert phone number into international format
            const number = phoneUtil.parseAndKeepRawInput(entity.point_person_phone, 'GB');
            const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
            const updatedValues = update(entity, {
              point_person_phone: { $set: internationalNumber },
            });

            axios[method](path, updatedValues)
              .then(({ data }) => {
                history.replace(`/organisation/${ entity.organisation_id }/entity/${ data.id }`);
              })
              .catch(catchAxios(setFieldError))
              .finally(() => setSubmitting(false));
          } }
        >
          { ({
               errors,
               values,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />
              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                label="Entity Name"
                value={ values.name }
                required
                name='name'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.name && touched.name && errors.name }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name='address'
                type="text"
                value={ values.address }
                label="Entity Address"
                required
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.address && touched.address && errors.address }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              { (initialLnAvailableKeys.current.value > 0 || lnUsedKeys > 0) &&
                <>
                  <Typography>
                    { `Organisation Lumi Nova Game Keys Available: ${ lnAvailableKeys }` }
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name='ln_game_keys'
                    type="number"
                    inputProps={ { min: 0, max: Math.min(gameKeyMax, thisLnKeys) } }
                    value={ values.ln_game_keys }
                    label="Lumi Nova Game Keys"
                    required
                    onChange={ updateKeys(lnOrgKeys, setLnAvailableKeys, handleChange) }
                    onBlur={ handleBlur }
                    helperText={ errors.ln_game_keys && touched.ln_game_keys && errors.ln_game_keys }
                    FormHelperTextProps={ { style: { color: helperTextColor } } }
                  />
                </>
              }

              { (initialCotsAvailableKeys.current.value > 0 || cotsUsedKeys > 0) &&
                <>
                  <Typography>
                    { `Organisation Champions of the Shengha Game Keys Available: ${ cotsAvailableKeys }` }
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name='cots_game_keys'
                    type="number"
                    inputProps={ { min: 0, max: Math.min(gameKeyMax, thisCotsKeys) } }
                    value={ values.cots_game_keys }
                    label="Champions of the Shengha Game Keys"
                    required
                    onChange={ updateKeys(cotsOrgKeys, setCotsAvailableKeys, handleChange) }
                    onBlur={ handleBlur }
                    helperText={ errors.cots_game_keys && touched.cots_game_keys && errors.cots_game_keys }
                    FormHelperTextProps={ { style: { color: helperTextColor } } }
                  />
                </>
              }

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name='point_person_name'
                type="text"
                value={ values.point_person_name }
                label="Point Person Name"
                required
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.point_person_name && touched.point_person_name && errors.point_person_name }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name='point_person_email'
                type="email"
                value={ values.point_person_email }
                label="Point Person Email"
                required
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.point_person_email && touched.point_person_email && errors.point_person_email }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name='point_person_phone'
                type="tel"
                value={ values.point_person_phone }
                label="Point Person Phone"
                required
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ touched.point_person_phone && errors.point_person_phone }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary">Submit</Button>
            </form>) }
        </Formik>
      </Container>
    </Card>
  );
}

EntityForm.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  entity: PropTypes.object,
  organisation: PropTypes.object,
};

export default EntityForm;
