import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Footer from '../content/Footer';


const sections = [
  {
    title: 'TRAINING',
    faqs: [
      {
        question: 'What if I didn\'t attend face-to-face training?',
        answer: () => (
          <div>
            <span>Training with providers from BfB Labs is a key component of successfully supporting young people and a great opportunity for you to connect with other teachers and clinicians in your area. If you were unable to attend:</span>
            <ul>
              <li>Contact BfB Labs at support@bfb-labs.com about joining another training session</li>
              <li>Review the training materials provided <Link to='/ln/resources'>here</Link></li>
            </ul>
          </div>
        ),
      },
      {
        question: 'Can I review training materials after the session?',
        answer: () => <span>Access your training pack <Link to='/ln/resources'>here</Link></span>,
      },
    ],
  },
  {
    title: 'CHOOSING GAME PLAYERS',
    faqs: [
      {
        question: 'How do I choose game players?',
        answer: () => (
          <div>
            <span>Lumi Nova is intended for:</span>
            <ul>
              <li>Ages 7-12</li>
              <li>Any gender</li>
              <li>May already be seeking treatment</li>
              <li>Mild to moderate anxiety</li>
            </ul>
            <span>Lumi Nova is NOT intended for young people fast-tracked to treatment and better managed by highly-experienced clinicians:</span>
            <ul>
              <li>High-risk cases</li>
              <li>Generalised anxiety</li>
              <li>Panic disorder</li>
            </ul>
          </div>
        ),
      },
      {
        question: 'What are the common signs of anxiety?',
        answer: () => (
          <div>
            <ul>
              <li>Anxious thoughts - expecting bad things to happen</li>
              <li>Anxious feelings - rapid heart rate, fast breathing, sweating, tummy aches, headaches</li>
              <li>Anxious behaviours - fight, flight, or safety-seeking</li>
            </ul>
          </div>
        ),
      },
      {
        question: 'What is mild to moderate anxiety?',
        answer: () => (
          <div>
            <span>Lumi Nova supports young people who show signs of anxiety that prevents them from doing things other people their age commonly do. These include:</span>
            <ul>
              <li>Separation anxiety - fear of being away from a particular person, usually a parent or carer,
                that may make it difficult for a young person to sleep alone, be home with other adults, sleep
                away from home, or attend school
              </li>
              <li>Social anxiety - fear of being in the spotlight, being judged, rejected, or embarrassed, that
                may make it difficult for a young person to take tests, speak in front of others, make mistakes,
                make new friends, be around strangers, attend social events, or attend school
              </li>
              <li>Specific phobias - fear of concrete objects that may make it difficult for young people to be
                around insects, dogs, visit high up places, or spend time in the dark
              </li>
            </ul>
          </div>
        ),
      },

    ],
  },
  {
    title: 'SUPPORTING GAME PLAYERS AND THEIR GUARDIANS',
    faqs: [
      {
        question: 'How do I introduce the game to a new guardian?',
        answer: () => (
          <div>
            <ul>
              <li>Some parents or guardians may be very aware of their child’s anxiety, and others may not, especially
                if it is specific to school activities
              </li>
              <li>Some guardians may have their own anxieties as well.</li>
              <li>It’s important not to make guardians feel like their child is being singled out, or as if they are to
                blame for their child’s anxiety.
              </li>
              <li>Explain a bit about the game and the opportunity for their child to build confidence, learn to cope
                with worries, and become more resilient.
              </li>
              <li>If they agree to their child’s participation, let them know to expect an SMS with a link to a brief
                introduction to the game and a short survey about their child’s anxiety.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: 'How do I introduce the game to a new young person?',
        answer: () => (
          <div>
            <span>It is important not to make a young person feel like they’re being singled out, as this may add to any anxiety they’re already experiencing.</span>
            <br />
            <span>Some guardians may prefer to introduce the game to their child themselves, so check in with guardians first. If they do want you to talk to the young person about the game, keep these tips in mind:</span>
            <ul>
              <li>Normalise anxiety - tell them that everyone worries sometimes!</li>
              <li>Ask open questions about some of the things they worry about - What do you worry about at home, at
                school, etc.?
              </li>
              <li>Make it fun! Tell them they can play a fun new game to help them learn how to build confidence and
                cope with their worries!
              </li>
              <li>Describe how the game works - they’ll get to choose a goal that may be hard for them to do now, and
                the game will help them break it down into really small steps so they feel less worried along the way.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: 'How do I send game information to guardians?',
        answer: () => (
          <div>
            <ul>
              <li>Guardian contact will be automated through the VitaMind Hub.</li>
              <li>Saving a new user profile in your VitaMind Hub list will automatically send an SMS to guardians with a
                game introduction and a brief anxiety survey.
              </li>
              <li>Once guardians complete this initial survey, the Hub will send another link to download the game, as
                well as ongoing information about their child’s progress through in-game and real-life challenges.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: 'How do I provide ongoing support to game players and guardians?',
        answer: () => (
          <div>
            <span>Guardian support is a critical component of success for Lumi Nova. Whenever possible, you might consider encouraging their continuing involvement in some of these ways:</span>
            <ul>
              <li>Check in with your game players and encourage them to tell their guardians about what they’re
                doing in the game
              </li>
              <li>Send email reminders to suggest that guardians check in with their young person about their
                progress in the game
              </li>
              <li>Organise school / organisation-wide coffee mornings or meetings for guardians to talk with you
                and with each other about their experiences, progress, and concerns with their young people
                playing Lumi Nova
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    title: 'GAME MAINTENANCE',
    faqs: [
      {
        question: 'What if I run out of licenses?',
        answer: 'When you only have a few licenses remaining, BfB Labs will automatically be notified and will arrange to provide you with more licenses if necessary. If you have any questions, you can contact BfB Labs at support@bfb-labs.com',
      },
      {
        question: 'What if a guardian forgets their game keys?',
        answer: 'Guardians will need their game key to verify the completion of their young person’s out of game challenges. If they forget their game key, they may be prompted to contact you to request it. You can provide the user key to them from their young person’s profile on your Young People list.',
      },
      {
        question: 'When can I expect to see results?',
        answer: 'You can expect to start seeing results once a young person starts playing the game.',
      },
    ],
  },
  {
    title: 'CAN\'T FIND WHAT YOU\'RE LOOKING FOR?',
    faqs: [
      {
        question: 'Contact',
        answer: 'For any other questions please email support@bfb-labs.com',
      },
    ],
  },
];


const useStyles = makeStyles(() => ({
  card: {
    minWidth: 275,
    marginBottom: '16px',
  },
  title: {
    padding: '20px',
    paddingTop: '50px',
  },
  heading: {
    marginBottom: '12px',
  },
  listItem: {
    width: '100%',
  },
}));

function QuestionItem(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Card className={ classes.card }>
      <CardContent>
        <Grid container direction='column' alignItems='flex-start' justify='center'>
          <Grid item xs={ 12 } className={ classes.listItem }>
            <ListItem button onClick={ handleClick } className={ classes.listItem }>
              <ListItemText
                primary={ props.question }
                primaryTypographyProps={ { style: { fontWeight: 800 } } }
              />
              { open ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>
            <Collapse in={ open } timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button>
                  <ListItemText primary={ typeof props.answer === 'function' ? props.answer() : props.answer } />
                </ListItem>

              </List>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}


export default function FAQs() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <Card className={ classes.card }>
        <CardContent>
          <Grid container direction='column' alignItems='flex-start' justify='center'>
            <Grid item xs={ 12 } className={ classes.heading }>
              <Typography variant="h4" align="left">
                FAQ
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography component="h1" align="left" variant="body1">
                Find everything you need to distribute the game and support young people and their guardians.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      { sections.map((title, index) => (
        <Grid key={ index } container direction="column">
          <Typography className={ classes.title } align='left' key={ index } color='textSecondary'>
            { title.title }
          </Typography>
          { title.faqs.map((question, index) => (
              <QuestionItem key={ index } question={ question.question } answer={ question.answer } />
            ),
          ) }

        </Grid>
      )) }
      <Footer />
    </Container>
  );
}
