import React from 'react';

export default function () {
  return (
    <footer className="center">
      <div style={ { display: 'inline-flex' } }>
        <img src="/ce-mark.png" alt="CE Mark" style={ { height: '100%', padding: 3 } } />
        <span>
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://bfb-labs.com/vitamind-hub-privacy-policy">Privacy Policy</a>
          &nbsp;|&nbsp;
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://www.bfb-labs.com/vmh-instructions-for-use">Instructions for Use</a>
          &nbsp;|&nbsp;
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://bfb-labs.com">BfB Labs © { new Date().getFullYear() }</a>
        </span>
      </div>
    </footer>
  );
}
