import React from 'react';
import DistributorForm from './DistributorForm';


function DistributorsCreatePage(props) {
  return <DistributorForm
    { ...props }
    path='/distributor'
    title={ 'Create a Distributor' }
  />;
}

export default DistributorsCreatePage;
