import React from 'react';
import { Button, Card, Grid } from '@material-ui/core';
import { axios } from '../../services/networkRequest';
import DistributorList from '../Distributors/DistributorList';
import { Delete, Edit, Settings } from '@material-ui/icons';
import { deleteAlert, restoreAlert } from '../../services/helpers';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { EntityDetails } from './InfoDisplay/EntityDetails';
import { withStyles } from '@material-ui/core/styles';

const useStyles = () => ({
  subGrid: {
    boxShadow: 'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
});

class EntityDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.deleteDistributor = this.deleteDistributor.bind(this);
    this.restoreDistributor = this.restoreDistributor.bind(this);
    this.populateEntity = this.populateEntity.bind(this);
    this.deleteEntity = this.deleteEntity.bind(this);
    this.editEntity = this.editEntity.bind(this);
    this.entitySettings = this.entitySettings.bind(this)
    this.deleteInvitee = this.deleteInvitee.bind(this);
    this.state = {
      ...props.location.state,
    };
  }

  populateEntity() {
    const id = this.props.match.params.ent_id;
    axios.get(`/entity/${ id }`)
      .then(({ data }) => {
        this.setState({ entity: data });
      });
  }

  componentDidMount() {
    if (!this.state.entity) {
      this.populateEntity();
    }
  }

  deleteInvitee({ first_name, last_name, id }) {
    if (typeof (id) === 'string') {
      id = id.startsWith('i') ? id.substring(1) : id;
    }

    deleteAlert(`${ first_name } ${ last_name }`, `/invite/distributor/${ id }`, this.populateEntity);
  }

  deleteDistributor({ name, id }) {
    if (typeof (id) === 'string') {
      id = id.startsWith('d') ? id.substring(1) : id;
    }

    deleteAlert(name, `/distributor/${ id }`, this.populateEntity);
  }

  restoreDistributor({ name, id }) {
    if (typeof (id) === 'string') {
      id = id.startsWith('d') ? id.substring(1) : id;
    }

    restoreAlert(name, `/distributor/restore/${ id }`, this.populateEntity);
  }

  deleteEntity() {
    const { entity } = this.state;

    axios.get(`/entity/${ entity.id }/delete_check`)
      .then(({ data }) => {
        const { distributors, active_users } = data;
        let message;
        if (active_users) {
          message = 'Are you sure you want to delete this entity as it contains active users?';
        } else if (distributors) {
          message = `${ distributors } will also be removed`;
        }

        deleteAlert(entity.name, `/entity/${ entity.id }`, () => {
          this.props.history.goBack();
        }, message);
      });
  }

  editEntity() {
    const { ent_id, org_id } = this.props.match.params;
    const { entity, organisation } = this.state;
    this.props.history.push(`/organisation/${ org_id }/entity/${ ent_id }/edit`, { entity, organisation });
  }

  entitySettings() {
    const { ent_id, org_id } = this.props.match.params;
    const { entity } = this.state;
    this.props.history.push(`/organisation/${ org_id }/entity/${ ent_id }/settings`, { entity });
  }

  render() {
    const { entity } = this.state;
    const { invitees, distributors } = entity || { invitees: [], distributors: [] };
    const { classes } = this.props;

    return (
      <div>
        <Grid container style={ { margin: '1rem 0' } }>
          <Grid item xs={ 12 }>
            <Card classes={ classes.subGrid }>
              <Button onClick={ this.editEntity }>
                <Edit />
              </Button>
              <Button onClick={ this.deleteEntity }>
                <Delete />
              </Button>
              <Button onClick={ this.entitySettings }>
                <Settings />
              </Button>
            </Card>
          </Grid>
        </Grid>
        <EntityDetails entity={ entity } />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={ () => this.props.history.push(`/organisation/${ this.props.match.params.org_id }/entity/${ this.props.match.params.ent_id }/distributor/invite`) }>
          Create Distributor
        </Button>
        <br />
        <br />
        <DistributorList
          distributors={ distributors }
          invitees={ invitees }
          deleteDistributor={ this.deleteDistributor }
          deleteInvitee={ this.deleteInvitee }
          restoreDistributor={ this.restoreDistributor }
          clickDistributor={ (event, rowData) => this.props.history.push(`/distributor/${ rowData.id }`, { distributor: rowData }) }
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(EntityDetailsPage);
