import React from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { axios, catchAxios } from '../../services/networkRequest';
import queryString from 'query-string';
import { getChain, ROLES } from '../../services/helpers';
import { Formik } from 'formik';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { helperTextColor } from '../../constants';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const phoneUtil = PhoneNumberUtil.getInstance();

class InvitationCreationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      address: '',
      phone: '',
      password: '',
      role: '',
    };
  }

  componentDidMount() {
    let searchObj = queryString.parse(this.props.location.search);
    axios.post('invite/validate', { code: searchObj.invite })
      .then(({ data }) => {
        const {
          first_name,
          last_name,
          email,
          role,
        } = data;

        const name = [first_name, last_name].reduce((acc, each) => {
          each && acc.push(each);
          return acc;
        }, []).join(' ');

        this.setState({
          email,
          role,
          name,
        });
      })
      .catch(error => {
        const network = getChain(error, 'response', 'data', 'error') || error.message;
        this.setState({
          network,
        });
      });
  }

  render() {
    return (
      <Container style={ {
        width: '100vw',
        position: 'relative',
      } } component="main" maxWidth="xs">
        <img src='/BfB_logo_blue.png' alt='logo' style={ {
          position: 'absolute',
          left: 0,
          width: '100px',
          height: 'auto',
        } } />
        <CssBaseline />
        <Typography component="h1" variant="h5">
          Create your account
        </Typography>
        { this.state.email ? <>
          <RequiredInfoText />
          <Formik
            initialValues={ {
              name: this.state.name,
              password: '',
              address: '',
              telephone: '',
            } }
            validate={ values => {
              const errors = {};
              const min_length = 10;
              if (!values.name) {
                errors.name = 'Please specify the distributor name';
              }
              if (!values.password) {
                errors.password = 'Password Required';
              } else if (values.password.length < min_length) {
                errors.password = `Minimum password length: ${ min_length } characters`;
              }
              if (this.state.role === ROLES.distributor) {
                if (!values.telephone) {
                  errors.telephone = 'Please specify a phone number';
                } else {
                  try {
                    const number = phoneUtil.parseAndKeepRawInput(values.telephone, 'GB');
                    if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                      errors.telephone = 'Please enter a valid number (with international dialling code if not +44)';
                    }
                  } catch (err) {
                    errors.telephone = 'Please enter a valid number (with international dialling code if not +44)';
                  }
                }
                if (!values.address) {
                  errors.address = 'Please specify an address';
                }
              }
              return errors;
            } }
            onSubmit={ ({ name, password, address, telephone }, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              let searchObj = queryString.parse(this.props.location.search);
              if (this.state.role === ROLES.distributor) {
                // Convert phone number into international format
                const number = phoneUtil.parseAndKeepRawInput(telephone, 'GB');
                telephone = phoneUtil.format(number, PhoneNumberFormat.E164);
              }
              axios.post(`${ this.state.role }/create`, {
                name,
                password,
                address,
                telephone,
                code: searchObj.invite,
              })
                .then(({ data }) => {
                  localStorage.setItem('user', JSON.stringify(data));

                  this.props.history.push('/');
                })
                .catch(err => {
                  catchAxios(setFieldError)(err);
                  setSubmitting(false);
                });
            } }
          >
            { ({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
               }) => (
              <form>
                <Error message={ this.state.network || errors['network'] } />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoFocus={ !!this.state.role }
                  type="text"
                  name="name"
                  id="name"
                  onBlur={ handleBlur }
                  value={ values.name }
                  onChange={ handleChange }
                  label="Name"
                  placeholder="Name"
                  required
                  helperText={ errors.name && touched.name && errors.name }
                  FormHelperTextProps={ { style: { color: helperTextColor } } }
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled={ true }
                  type="email"
                  name="email"
                  id="email"
                  onBlur={ handleBlur }
                  value={ this.state.email }
                  onChange={ handleChange }
                  label="Email"
                  placeholder="Email"
                  required
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="password"
                  name="password"
                  id="password"
                  onBlur={ handleBlur }
                  value={ values.password }
                  onChange={ handleChange }
                  label="Password"
                  placeholder="Password"
                  required
                  helperText={ errors.password && touched.password && errors.password }
                  FormHelperTextProps={ { style: { color: helperTextColor } } }
                />
                { this.state.role === ROLES.distributor &&
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="text"
                  name="address"
                  id="address"
                  onBlur={ handleBlur }
                  value={ values.address }
                  onChange={ handleChange }
                  label="Work Address"
                  placeholder="Address"
                  required
                  helperText={ errors.address && touched.address && errors.address }
                  FormHelperTextProps={ { style: { color: helperTextColor } } }
                /> }
                { this.state.role === ROLES.distributor &&
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="tel"
                  name="telephone"
                  id="telephone"
                  onBlur={ handleBlur }
                  value={ values.telephone }
                  onChange={ handleChange }
                  label="Work Phone"
                  placeholder="Telephone"
                  required
                  helperText={ errors.telephone && touched.telephone && errors.telephone }
                  FormHelperTextProps={ { style: { color: helperTextColor } } }
                /> }
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={ isSubmitting }
                  onClick={ handleSubmit }
                >
                  Submit
                </Button>
              </form>
            ) }
          </Formik>
        </> : <CircularProgress style={ { marginTop: '46px', marginBottom: '20px' } } /> }
        <Typography style={ { marginTop: '16px' } } color="textPrimary">Already have an account? <Link
          style={ { textDecoration: 'underline' } } to='/login'>Log in here</Link></Typography>
      </Container>
    );
  }
}

export default InvitationCreationPage;
