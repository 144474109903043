import React, { useState, useEffect } from 'react';
import { getChain } from '../../../services/helpers';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  dropdownTextColor,
  greyBorderColor,
  numberTextColor,
} from '../../../Styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Heart from '@material-ui/icons/FavoriteBorderOutlined'
import Timer from '@material-ui/icons/Timer';
import CotsYoungPersonPerformanceChart from '../../CotsYoungPersonPerformanceChart';
import ContactDetails from './ContactDetails';
import UsageGraph from '../UsageGraph';
import ExitButton from '../../ExitButton';

const useStyles = makeStyles({
  select: {
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '14px',
    padding: '2px 10px',
    minWidth: '160px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none'
    }
  }
});

export function CotsYoungPersonReport(props) {
    const classes = useStyles();

    const rowData = props.location.state.userData;    
    const [userData, setUserData] = useState(rowData);

    const gameTime = getChain(userData, 'stats', 'game_time');
    const lastUsedDateString = gameTime && gameTime.lastLoggedIn && new Date(gameTime.lastLoggedIn).toLocaleDateString();
    const totalTime = getChain(gameTime, 'timeSpent');
    const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
    const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';
    const improving = getChain(gameTime, 'improving');

    const graph_data = getChain(userData, 'performance_data');
    const first_graph_data = getChain(graph_data, 'first_graph');
    const recent_graph_data = getChain(graph_data, 'recent_graph');

    useEffect(() => {
      setTimeout(window.print, 2000);
    }, []);

    return(
      <Grid 
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      key={ userData.id }>

        <Grid container id="unzoom" component="main" xs={11} style={ { paddingTop: '30px' } }
              spacing={ 3 }>
          { userData.first_play && <>
            <Grid item xs={ 11 }>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Grid container>
                    <Grid item xs={ 5 }><Typography color="textPrimary">{ `${ userData.first_name }'s game time` }</Typography></Grid>
                    <Grid item xs={ 5 }><Typography color="textSecondary"
                                                    align="right">{ `Last logged in: ${ lastUsedDateString || '' }` }</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={ 1 }>
              <ExitButton history={ props.history } />
            </Grid>
            <Grid item xs={ 3 }>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">Total duels</Typography>
                  <Grid align='left' container>
                    <Grid item xs={ 10 }><Typography 
                      style={ { fontSize: '30px', color: numberTextColor } }>{ gameTime.totalDuels }</Typography></Grid>
                    <Grid item xs={ 2 }><GameAsset style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={ 3 }>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">Average time per session</Typography>
                  <Grid align='left' container>
                    <Grid item xs={ 10 }>
                      <Typography style={ { fontSize: '30px', color: numberTextColor, marginRight: '2px' } }
                                  component='span'>{ gameTime.timeAverage }</Typography>
                      <Typography color="textPrimary" component='span'>mins</Typography>
                    </Grid>
                    <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={ 3 }>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">Total time spent playing</Typography>
                  <Grid align='left' container>
                    <Grid item xs={ 10 }>
                      <Typography style={ { fontSize: '30px', color: numberTextColor, marginRight: '2px' } }
                                  component='span'>{ totalHours }</Typography>
                      <Typography color="textPrimary" style={ { marginRight: '8px' } } component='span'>hrs</Typography>
                      <Typography style={ { fontSize: '30px', color: numberTextColor, marginRight: '2px' } }
                                  component='span'>{ totalMinutes }</Typography>
                      <Typography color="textPrimary" component='span'>mins</Typography>
                    </Grid>
                    <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={ 3 }>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">Player Performance</Typography>
                  <Grid align='left' container>
                    <Grid item xs={ 10 }>
                        <Typography style={ { fontSize: '30px', color: numberTextColor } }>
                        {(() => {
                        switch (improving) {
                          case 1: return "Improving";
                          case 0: return "Not Improving";
                          default: return "Insufficient Data";
                        }
                      })()}</Typography>
  
                        
                      </Grid>
                    <Grid item xs={ 2 }><Heart style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
                        
            <UsageGraph classes={classes} playerName={userData.first_name} userData={userData} setUserData={(userData) => {
              setUserData(userData);
            } } chartUrl={`/cots/young_persons/${ userData.id }/usage_chart`} />
            

            <Grid item xs={ 12 }>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Grid container>
                    <Grid item xs={ 12 }>
                      <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>Performance over first 20 duels using game metric (Bfb Labs)</Typography>
                    </Grid>
                  </Grid>
                  <CotsYoungPersonPerformanceChart data={ first_graph_data }/>
                  <Grid item xs={ 12 }>
                      <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>Performance over last 20 duels using game metric (Bfb Labs)</Typography>
                    </Grid>
                  <CotsYoungPersonPerformanceChart data={ recent_graph_data }/>
                </CardContent>
              </Card>
            </Grid>

          </> }
          <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid item xs={ 10 }>
                    <Typography color="textSecondary">CONTACT</Typography>
                  </Grid>
                  <Grid item xs={ 2 }>
                  </Grid>
                </Grid>
                <ContactDetails { ...userData } />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    )
}