import { useState, useEffect } from 'react';

export function useViewport() {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleWindowResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const views = {
        smallMobile: width <=Width.SmallPhone,
        mobile: width <= Width.Phone,
        tablet: width <=Width.SmallTablet,
        largeTablet: width <= Width.LargeTablet,
        narrowDesktop: width <= Width.NarrowDesktop,
        desktop: width <=Width.Desktop,
    }

    return { width, height, views };
}

export const Width = {
    SmallPhone: 350,
    Phone: 600,
    SmallTablet: 1000,
    LargeTablet: 1200,
    NarrowDesktop: 1400,
    Desktop: 1600,
}