import React from 'react';
import OrganisationForm from './OrganisationForm';


const OrganisationEditPage = props =>
  <OrganisationForm
    { ...props }
    path={ `/organisation/${ props.location.state.organisation.id }` }
    organisation={ props.location.state.organisation }
    title={ `Edit ${ props.location.state.organisation.name }` }
  />;

export default OrganisationEditPage;
