import React, { useEffect, useState } from 'react';
import { Card, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { axios } from '../services/networkRequest';
import { getChain } from '../services/helpers';
import { useParams } from 'react-router-dom';
import SelfSignUpFooter from './SelfSignUp/Footer';
import SelfSignUpHeader from './SelfSignUp/Header';
import { CustomSupportInfo } from './Error';
import { useViewport } from '../utilities/viewport';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  content: {
    textAlign: 'left',
    fontSize: '1rem',
  },
  container: {
    margin: '1rem 0',
    display: 'grid',
    placeItems: 'start start',
  },
  link: {
    color: '#c9218e',
    fontWeight: 'bold',
  },
  card: {
    borderRadius: '1rem',
    marginTop: '2rem',
    padding: '2rem 1rem',
  },
  gameLogo: {
    width: '10vw',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',

  },
}));

export default function SuccessPage({ location, history }) {

  const { keycode } = useParams();

  const classes = useStyles();

  const originalEntitySettings = location.state?.entitySettings || undefined;

  const [entitySettings, setEntitySettings] = useState(originalEntitySettings);
  const [error, setError] = useState(false);

  const guid = getChain(entitySettings, 'self_sign_up_settings', 'guid');
  const customSupportInfo = getChain(entitySettings, 'custom_support_info');
  const showCustomSupportInfo = getChain(customSupportInfo, 'enable');

  useEffect(() => {
    if (!keycode) {
      setError(true);
    } else {

      if (!entitySettings) {
        axios.get(`/entity/${ keycode }`)
          .then(({ data }) => {
            setEntitySettings(data);
          })
          .catch(() => setError(true));
      }
    }

  }, [entitySettings, keycode, history]);

  const { views: { mobile } } = useViewport();

  return (
    <>
      <SelfSignUpHeader guid={ guid } />
      <Container component="main">
        <Card className={ classes.card }>
          <Container maxWidth="md">
            <Grid container justify="center">
              <img src="/ln_logo_transparent_background.png" alt="Lumi Nova logo" className={ classes.gameLogo } />
            </Grid>
            <br />
            {
              error ?
                <Grid container>
                  <Grid item xs={ 12 }>
                    <Typography component="h1" variant="h5" className={ classes.title }>
                      Sorry, there's been a problem.
                    </Typography>
                  </Grid>
                </Grid> :
                <Grid container>
                  <Grid item xs={ 12 }>
                    <Typography component="h3" variant="h6" className={ classes.title }>
                      All done! We've sent you a text message to get started.
                    </Typography>
                  </Grid>

                  <Grid container justify="flex-start" className={ classes.container }>
                    <Typography component="h3" variant="h6" className={ classes.title }>
                      Next Steps
                    </Typography>
                    <Typography component="p" variant="h6" className={ classes.content }>
                      Check your mobile phone and click on the link we've sent you to get your
                      Lumi Nova game key and download link.
                    </Typography>
                  </Grid>

                  <Grid container justify="flex-start" className={ classes.container }>
                    <Typography component="h3" variant="h6" className={ classes.title }>
                      Support
                    </Typography>
                    {
                      customSupportInfo && showCustomSupportInfo &&
                      <>
                        <Typography component="p" variant="h6" className={ classes.content }>
                          Lumi Nova has been provided to you
                          through { getChain(customSupportInfo, 'entity_name') }
                        </Typography>
                        <br />
                        <Grid item xs={ mobile ? 12 : 6 }>
                          <CustomSupportInfo customSupportInfo={ customSupportInfo } />
                        </Grid>
                      </>
                    }
                  </Grid>
                </Grid>
            }
            <br />
            <Typography style={ { textAlign: 'left' } }>
              Please visit the official <a href="https://www.bfb-labs.com/luminova"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={ classes.link }>Lumi Nova website</a> to learn more about
              supporting your child with Lumi Nova.
            </Typography>
          </Container>
        </Card>
      </Container>
      <SelfSignUpFooter />
    </>
  );
}

export function IconLabel({ icon, children }) {

  const { views: { tablet } } = useViewport();

  return (
    <Grid container>
      <Grid item xs={ tablet ? 2 : 1 } style={ { textAlign: 'left' } }>
        { icon }
      </Grid>
      <Grid item xs={ tablet ? 10 : 11 }>
        <Typography component="p" varinat="p" style={ { textAlign: 'left', whiteSpace: 'nowrap' } }>
          { children }
        </Typography>
      </Grid>
    </Grid>
  )
}