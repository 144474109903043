import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryLine, VictoryScatter, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress, Typography } from '@material-ui/core';
import { barColor } from '../../Styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 230,
    width: 600,
    paddingBottom: 200,
    marginBottom: 20
  },
  centre: {
    margin: 'auto',
  },
  key: {
    marginTop: '64px',
    border: '1px solid black',
    width: '30%',
    padding: 5
  },
  dot: {
    height: "10px",
    width: "10px",
    backgroundColor: barColor,
    borderRadius: "50%",
    marginLeft: '24px',
    marginTop: '4px'
  }
}));

const CORSSurveyResults = (props) => {
  const classes = useStyles();
  if (!props.results) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const chartData = props.results.map((item, index) => {
  const date = new Date(item.survey_date);
  const dateString = date.toDateString();
  const parts = dateString.split(' ');

  const axisLabel = parts[2] + ' ' + parts[1];
  return {
      day: index + 1,
      axisLabel,
      total: Number(item.total)
    }
  });
  
  const dayValues = chartData.map(item => item.day);
  const labels = chartData.map(item => item.axisLabel);
  let tickValues = [0, 5, 10, 15, 20, 25, 30, 35, 40];

  return (
    <div className={ classes.root }>
    <Grid style={{ width: '100%' }}>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                     tickFormat={ labels }
                     label={'Date'}
                     style={ { axisLabel: { padding: 35 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     label='Child Outcome Rating Scale (CORS) Results'
                     style={ { axisLabel: { padding: 40 } } }
        />

        <VictoryLine 
            y={() => 28} 
            style={ { data : {
                strokeDasharray: "5,5",
                stroke: 'limegreen'
            } } }
        />

        <VictoryLine
          tickValues={ dayValues }
          style={ { data: { stroke: barColor } } }
          data={ chartData }
          x="day"
          y="total"
        />
        <VictoryScatter
          tickValues={ dayValues }
          style={ { data: { fill: barColor } } }
          data={ chartData }
          x="day"
          y="total"
        />
      </VictoryChart>
          <div className={classes.key}>
              <Typography align="left" style={{fontWeight: 600, fontSize: '12px', marginLeft: '10px'}}>KEY</Typography>
              <Grid container direction={'row'} style={{ marginBottom: '12px' }}>
                  <Grid item xs={4}>
                    <div style={{ color: 'limegreen', fontWeight: 600 }}>- - -</div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography align="left" style={{ fontSize: '12px' }}>Cut off for carer reporting on child score (28)</Typography>
                  </Grid>
              </Grid>
              <Grid container direction={'row'}>
                  <Grid item xs={4}>
                  <div className={classes.dot}></div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography align="left" style={{ fontSize: '12px' }}>CORS</Typography>
                  </Grid>
              </Grid>
          </div>
      </Grid>
      
    </div>
  );
};

export default withSize()(CORSSurveyResults);