import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Email, Language, Phone } from '@material-ui/icons';
import { getChain } from '../services/helpers';
import { IconLabel } from './SuccessPage';

export default function Error({ message, style }) {
  if (!message) {
    return null;
  } else if (!Array.isArray(message)) {
    message = [message];
  }

  return message && (
    <div style={ style }>
      { message.map((each, index) => <Typography key={ index } style={ { color: 'red' } }>{ each }</Typography>) }
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  container: {
    margin: '1em 0',
    display: 'grid',
    placeItems: 'start start',
  },
  gameLogo: {
    width: '100%',
    maxWidth: '10rem',
    margin: '1rem 0',
  },
  link: {
    color: '#c9218e',
    fontWeight: 'bold',
  },
}));

export function ErrorPage({ entitySettings, signUpUrlError }) {
  const classes = useStyles();

  const customSupportInfo = getChain(entitySettings, 'custom_support_info');

  return (
    <Grid style={ { display: 'grid', placeItems: 'center', margin: '3rem 0', minHeight: 'calc(100vh - 23em)' } }>
      <div>
          {
            !signUpUrlError &&
        <Grid container justify="center">
            <Typography component="h1" variant="h5" className={ classes.title }>
              Sorry, there's been a problem
            </Typography>
        </Grid>
          }
        { customSupportInfo && <CustomSupportInfo customSupportInfo={ customSupportInfo } /> }

        { signUpUrlError && <SignUpError signUpUrlError={ signUpUrlError } /> }

        <br/>
        <Typography style={ { textAlign: 'left' } }>
          Please visit the official <a href="https://www.bfb-labs.com/luminova"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       className={ classes.link }>Lumi Nova website</a> to learn more about
          supporting your child with Lumi Nova.
        </Typography>
      </div>
    </Grid>
  );
}

export function CustomSupportInfo({ customSupportInfo }) {
  const classes = useStyles();

  const email = getChain(customSupportInfo, 'email');
  const phone = getChain(customSupportInfo, 'phone');
  const website = getChain(customSupportInfo, 'website');
  return (
    <Grid container justify="center" className={ classes.container }>
      <Typography component="p" variant="h6" style={ { textAlign: 'left', fontSize: '1rem' } }>
        For more information or support please contact:
      </Typography>
      <br />
      { email &&
      <IconLabel icon={ <Email /> }>
        { email }
      </IconLabel>
      }
      { phone &&
      <IconLabel icon={ <Phone /> }>
        { phone }
      </IconLabel>
      }
      { website &&
      <IconLabel icon={ <Language /> }>
        { website }
      </IconLabel>
      }
    </Grid>
  );
}

export function SignUpError({ signUpUrlError }) {
  const classes = useStyles();

  const errorResponse = getChain(signUpUrlError, 'response', 'data');

  return (
    <Grid container style={ { display: 'block', textAlign: 'center' } }>
      <Typography component="h3" variant="h6" className={ classes.title }>
        { errorResponse.title }
      </Typography>
      <Typography component="p" variant="h6" className={ classes.content }>
        { errorResponse.body }
      </Typography>
    </Grid>
  )
}